import {isMobile} from "./devices";

let maxItemsVisible = 2;
export function initBreadcrumb()
{
    let expandButton = document.querySelector('.breadcrumb.breadcrumb-expandable .breadcrumb-expand');
    let items = document.querySelectorAll('.breadcrumb.breadcrumb-expandable .breadcrumb-item:not(.hidden)');
    if (items.length > maxItemsVisible && isMobile()) {
        items = Array.prototype.slice.call(items);
        items.reverse();
        items.forEach(function(item, index) {
            let numberOfItem = index + 1;
            if (numberOfItem > maxItemsVisible) item.classList.add('d-none');
        });
        expandButton.classList.remove('d-none');
    }
    let breadcrumbExpandable = document.querySelector('.breadcrumb.breadcrumb-expandable');
    if (breadcrumbExpandable) {
        breadcrumbExpandable.classList.remove('invisible');
    }
}