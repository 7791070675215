export function fixAllSVGs() {
    let svg = document.getElementsByTagName('svg');
    let arr = Array.from(svg);
    arr.forEach(function(item, i){
        fixSVG(item);
    });
}

export function fixAllContainerSVGs(container) {
    let test = container.getElementsByTagName('svg');
    let arr = Array.from(test);
    arr.forEach(function(item, i){
        fixSVG(item);
    });
}

export function fixSVG(item) {
    if (item.getAttribute('tvFixed') === '1') {
        return;
    }
    let svg = item;
    let path = svg.children;
    let Arr = Array.from(path);
    let svgBBox = getSvgBBox(item);

    let pathHeight = svgBBox.height;
    let pathWidth  = svgBBox.width;

    //Set the correct height / widht of SVG (rounded up to 1)
    let boxWidth = Math.ceil(pathWidth);
    let boxHeight = Math.ceil(pathHeight);
    item.setAttribute('viewBox','0 0 '+ boxWidth+' '+ boxHeight);


    //figure out the postion of the path relative to its parent

    //use CSS translate to move the paths...
    let x = svgBBox.x *-1;
    let y = svgBBox.y *-1;
    Arr.forEach(function (item) {
        item.setAttribute('transform', 'translate('+x+', '+y+')');
        item.setAttribute('fill', 'currentcolor');
    });

    item.setAttribute('tvFixed','1');
}

function getSvgBBox(svg) {
    let tempDiv = document.createElement('div');
    tempDiv.setAttribute('style', "position:absolute; visibility:hidden; width:0; height:0");
    document.body.appendChild(tempDiv);
    let tempSvg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
    tempDiv.appendChild(tempSvg);
    let tempEl = svg.cloneNode(true);
    tempSvg.appendChild(tempEl);
    let bb = tempEl.getBBox();
    document.body.removeChild(tempDiv);

    return bb;
}