import {clickEvent, submitEvent} from "../../webpack/modules/events";
import {slideDown, slideUp} from "../../webpack/modules/element-visibility";
import {sendForm} from "../../webpack/modules/form";
import {openModalFromHtml} from "../../webpack/modules/modal";

clickEvent('#message-sos .open-form', false, function(element) {
    openForm();
});

clickEvent('#message-sos .close-form', false, function() {
    closeForm();
});

submitEvent('#message-sos form', false, function(form, event) {
    event.preventDefault();
    sendForm(
        form,
        function (data) {
            openModalFromHtml(data.modal);
            closeForm();
        },
        function(data) {
            openModalFromHtml(data.modal);
            closeForm();
        });
});

function openForm()
{
    let container = document.querySelector('#message-sos');
    let openForm = container.querySelector('.open-form');
    let form = container.querySelector('.form');
    slideUp(container, 200, function() {
        openForm.classList.remove('d-flex');
        openForm.classList.add('d-none');
        form.classList.remove('d-none');
        slideDown(container);
    });
}

function closeForm()
{
    let container = document.querySelector('#message-sos');
    let openForm = container.querySelector('.open-form');
    let form = container.querySelector('.form');
    slideUp(container, 200, function() {
        form.classList.add('d-none');
        openForm.classList.remove('d-none');
        openForm.classList.add('d-flex');
        slideDown(container);
    });
}