export function makeUrl(prefix, params)
{
    let url = prefix;

    for (let key in params) {
        let paramPrefix = url.indexOf('?') == -1 ? '?' : '&';
        url = url + paramPrefix + key + '=' + params[key];
    }

    return url;
}