import {clickEvent} from "../../webpack/modules/events";
import {get} from "../../webpack/modules/ajax";
import {getModal, openModalFromHtml} from "../../webpack/modules/modal";
import {isDesktop, isIOS} from "../../webpack/modules/devices";
import {
    addIOSWindowFix,
    clearIOSWindowFix,
    loadImageSlider,
    loadMobileZoom,
    removeZoomOfAllDesktopImages,
    zoomImage
} from "../../webpack/modules/popup-image-zoom";
import {ga4TrackSimpleEvent, trackEvent} from "../../webpack/modules/analytics";
import {trackProductPageGa4Events} from "../../webpack/modules/product";

let isZoomed = false;
let mainSplide = null;

clickEvent('.images-zoom-popup', true, function (element, e) {
    let target = e.target;
    if (target.classList.contains('open-ar-modal') || target.closest('.open-ar-modal')) return;
    let productId = element.closest('.product-scope').dataset.productId;
    let position = element.dataset.position;
    if (!isDesktop() && isIOS()) addIOSWindowFix();

    openPopUp(productId, Number(position));
});

clickEvent('.close-images-zoom-popup', true, function () {
    if (!isDesktop() && isIOS()) clearIOSWindowFix();
});

clickEvent('#modal-image-zoom .main-slider .desktop-zoom', true, function (element, e) {
    if (isDesktop()) {
        isZoomed = !isZoomed;
        zoomImage(element, isZoomed);
    }
});

let ajaxModal = null;
let modalHTML = '';
function openPopUp(productId, position)
{
    trackProductPageGa4Events('ImageZoom', '');
    trackEvent('ProductPage', 'PopupImageZoom', 'open');
    let modal = document.getElementById('modal-image-zoom');

    if (modal && modal.dataset.productId == productId) {
        if (isIOS() && modalHTML.length) {
            modal.remove();
            openModalFromHtml(modalHTML, {}, false);
            setTimeout(function(){
                let modal = document.getElementById('modal-image-zoom');
                mainSplide = loadImageSlider(modal, mainSplide);
                loadMobileZoom(modal, mainSplide);
                mainSplide.go(position);
            }, 200);
        } else {
            let bootstrapModal = getModal('modal-image-zoom');
            if (isDesktop()) {
                let modal = document.getElementById('modal-image-zoom');
                removeZoomOfAllDesktopImages(modal);
            }
            bootstrapModal.show();
            setTimeout(function(){
                mainSplide.go(position);
            }, 200);
        }
    } else {
        if (modal) modal.remove();
        if (ajaxModal) ajaxModal.abort();

        ajaxModal = get(
            '/ajax/website/product/get-modal-image-zoom',
            {
                productId: productId
            },
            function(response) {
                ajaxModal = null;
                modalHTML = response.modal;
                openModalFromHtml(response.modal, {}, false);
                setTimeout(function(){
                    let modal = document.getElementById('modal-image-zoom');
                    mainSplide = loadImageSlider(modal, mainSplide);
                    loadMobileZoom(modal, mainSplide);
                    mainSplide.go(position);
                }, 200);
            },
            function () {
                ajaxModal = null;
            }
        );
    }
}