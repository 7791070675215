import {clickEvent} from "../../webpack/modules/events";
import {post} from "../../webpack/modules/ajax";
import {onVisible} from "../Tools/visible-element-observer";

onVisible(document.querySelectorAll(".fire-footer-events"), function (element) {
    getPhoneInfo();
}, 0.1);

clickEvent('.social-link', false, function(element) {
    let url = element.dataset.url;

    window.open(url);
});

function getPhoneInfo() {
    post(
        '/ajax/website/get-phone-info',
        {},
        function(response) {
            document.getElementById('show-contact-info').innerHTML = response.view;
        }
    );
}