export function getPriceFormat(price)
{
    let htmlTag = document.getElementsByTagName('html');

    if (htmlTag && htmlTag[0].getAttribute('lang') === 'da-DK') {
        price = new Intl.NumberFormat('da-DK', { style: 'currency', currency: 'DKK', useGrouping : false })
            .format(price)
            .replace("kr.", "");
    }

    return price;
}
