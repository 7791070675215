import {post} from "./ajax";

export function renderDropdown(type, callback)
{
    post(
        '/ajax/website/header/render-dropdown',
        {
            type: type
        },
        function(response) {
            callback(response.view);
        }
    );
}