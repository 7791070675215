import {clickEvent} from "../../webpack/modules/events";
import {ga4TrackSimpleEvent} from "../../webpack/modules/analytics";
import {initBreadcrumb} from "../../webpack/modules/breadcrumb";

document.addEventListener('DOMContentLoaded', function () {
    initBreadcrumb();
});


clickEvent('.breadcrumb.breadcrumb-expandable .breadcrumb-expand', false, function(button) {
    button.classList.add('d-none');
    let items = document.querySelectorAll('.breadcrumb.breadcrumb-expandable .breadcrumb-item:not(.hidden)');
    items.forEach(function(item, index) {
        item.classList.remove('d-none');
    });
    ga4TrackSimpleEvent({
        event: 'breadcrumb',
        method: 'see_more'
    });
});
