import {clickEvent, submitEvent, keyupEvent} from "../../webpack/modules/events";
import {getModal, openModalFromHtml} from "../../webpack/modules/modal";
import {get, post} from "../../webpack/modules/ajax";
import {slideDown, slideUp} from "../../webpack/modules/element-visibility";
import {sendForm} from "../../webpack/modules/form";
import {getCookie, setCookie} from "../../../library/cookies";
import {ga4TrackSimpleEvent} from "../../webpack/modules/analytics";

let cookieName ="newsletter_loyalty_cookie";
var popupEventSend = false;

setTimeout(() => {
    let element = document.getElementById('loyalty-newsletter');
    if (!element) return;
    if (!getCookie(cookieName)) {
        element.classList.remove('opacity-0');
    } else if(!element.classList.contains('opacity-0')) {
        element.classList.add('opacity-0');
    }
}, 500);

launchNewsletterPopup();

clickEvent('.open-modal-newsletter', false, function() {
    openModal();
    if (!popupEventSend) {
        dataLayer.push({
            event: 'newsletter_show',
            method: 'loyalty'
        });
        popupEventSend = true;
    }
});

clickEvent('.close-newsletter-loyalty', false, function () {
    document.getElementById('loyalty-newsletter').classList.add('d-none');
    let date = new Date();
    date.setTime(date.getTime() + (15 * 60 * 1000));
    let expires = "expires="+ date.toUTCString();
    document.cookie = cookieName + "=" + 'true' + ";" + expires + ";path=/";
});

clickEvent('#popup-newsletter .add-newsletter', true, function () {
    let email = document.querySelector('#email-news');
    let terms = document.querySelector('#privacy-terms');
    let newsletter = document.querySelector('#mailing');

    if (email.value == '' || !terms.checked || !newsletter.checked) {
        ga4TrackSimpleEvent({
             event: 'popup_newsletter',
            method: 'error',
        });
    } else {
        ga4TrackSimpleEvent({
             event: 'popup_newsletter',
            method: 'suscribe',
        });
    }
});

submitEvent('#popup-newsletter .add-newsletter-user', true, function(form, event) {
    event.preventDefault();
    sendForm(
        form,
        function(data) {
            document.getElementById('form-data-modal').classList.add('d-none');
            document.getElementById('form-voucher-modal').classList.remove('d-none');
            document.getElementById('form-voucher-modal').innerHTML = data.view;
            dataLayer.push({
                event: 'newsletter_subscribe',
                method: 'popup'
            });
        }
    );
    newsletterPopupDismissed();
});

let writeMail = true;
keyupEvent('#popup-newsletter #email-news', true, function() {
    setTimeout(() => {
        if (writeMail) {
            ga4TrackSimpleEvent({
                event: 'popup_newsletter',
               method: 'type_email',
            });
            writeMail = false;
      }
    }, 2000);
});

clickEvent('.dismiss-newsletter-popup', true, function() {
    newsletterPopupDismissed();
});

let writeMailFooter = true;
keyupEvent('#form-data-footer #email-news-footer', true, function() {
    setTimeout(() => {
        if (writeMailFooter) {
            ga4TrackSimpleEvent({
                event: 'footer_newsletter',
                method: 'type_email',
            });
            writeMailFooter = false;
        }
    }, 2000);
});

clickEvent('#form-data-footer .add-newsletter', true, function () {
    let email = document.querySelector('#email-news-footer');
    let terms = document.querySelector('#news-privacy-terms-footer');
    let newsletter = document.querySelector('#news-mailing-footer');

    if (email.value == '' || !terms.checked || !newsletter.checked) {
        ga4TrackSimpleEvent({
            event: 'footer_newsletter',
            method: 'error',
        });
    } else {
        ga4TrackSimpleEvent({
            event: 'footer_newsletter',
            method: 'suscribe',
        });
    }
});

submitEvent('#form-data-footer .add-newsletter-user', true, function(form, event) {
    event.preventDefault();
    sendForm(
        form,
        function(data) {
            document.getElementById('form-data-footer').classList.add('d-none');
            let formVoucher = document.getElementById('form-voucher-footer');
            formVoucher.innerHTML = data.view;
            let texts = formVoucher.querySelectorAll('span, p');
            texts.forEach((text) => text.classList.add('text-white'));
            formVoucher.classList.remove('d-none');
            formVoucher.querySelector('#voucher-code').classList.remove('text-dark');
            formVoucher.querySelector('#voucher-code').classList.add('text-white');
            dataLayer.push({
                event: 'newsletter_subscribe',
                method: 'popup'
            });
        }
    );
});

function openModal(autoModal) {
    let payload = {};
    if (autoModal) {
        payload = {autoModal: true};
    }
    post(
        '/ajax/website/popup/newsletter',
        payload,
        function(response) {
            openModalFromHtml(response.modal, {}, false);
            document.getElementById('form-data-modal').classList.remove('d-none');
            document.getElementById('form-voucher-modal').innerHTML = '';
            document.getElementById('form-voucher-modal').classList.add('d-none');
            document.getElementById('popup-newsletter-form').reset();
            let popup = getModal('popup-newsletter');
            if (popup) {
                popup._element.addEventListener('hide.bs.modal', function () {
                    newsletterPopupDismissed();
                });
            }
        }
    );
}

function canShowNewsletterPopup() {
    let canShow = true;
    let skipPopup = document.getElementsByClassName('skip-newsletter-popup').length;
    if (!getCookie('_tv_cookie_choice')) return false;
    if (!getCookie(cookieName) && !skipPopup) {
        if (typeof (Storage) !== 'undefined') {
            if (localStorage.getItem('tvn_shnewspop')) {
                let d1 = new Date();
                canShow = ((d1.getTime() - localStorage.getItem('tvn_shnewspop'))/1000/86400) > 30;
            } else {
                canShow = true;
            }
        } else {
            canShow = !(getCookie('tvn_shnewspop') === '1');
        }
    } else {
        canShow = false;
    }

    return canShow;
}

function newsletterPopupDismissed() {
    if (typeof (Storage) !== 'undefined') {
        let d = new Date();
        localStorage.setItem('tvn_shnewspop', d.getTime());
    } else {
        setCookie('tvn_shnewspop', '1', 30);
    }
}

let newsletterTimeout = null;
export function launchNewsletterPopup() {
    if (canShowNewsletterPopup()) {
        clearTimeout(newsletterTimeout);
        newsletterTimeout = setTimeout(() => {
            if (canShowNewsletterPopup()) {
                if (/*document.getElementById('loyalty-newsletter') &&*/ !getModal('popup-newsletter')) {
                    openModal(true);
                    if (!popupEventSend) {
                        ga4TrackSimpleEvent({
                            event: 'popup_newsletter',
                            method: 'view',
                        });
                        popupEventSend = true;
                    }
                }
            }
        }, 30000);
    }
}