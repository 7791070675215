import {clickEvent} from "../../webpack/modules/events";
import {slideDown, slideUp} from "../../webpack/modules/element-visibility";

clickEvent('.card-collapse .card-header', true, function(element) {
    let card = element.closest('.card-collapse');
    let body = card.querySelector('.card-body');

    if (body.offsetHeight) {
        slideUp(body, 200, function() {
            card.classList.add('collapsed');
        });
    } else {
        slideDown(body, 200, function() {
            card.classList.remove('collapsed');
        });
    }
});