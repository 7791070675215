import {errorEvent} from "../../webpack/modules/events";

errorEvent('img[data-webp-alt]', false, (element) => useWebpAltImage(element));

const observer = new MutationObserver(handleMutation);
observer.observe(document, { childList: true, subtree: true });

function handleMutation(mutationsList)
{
    for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
            for (let node of mutation.addedNodes) {
                if (node.tagName && node.tagName.toLowerCase() === 'img' && node.dataset.webpAlt) {
                    node.addEventListener('error', () => useWebpAltImage(node));
                }
            }
        }
    }
}

function useWebpAltImage(element)
{
    element.src = element.dataset.webpAlt;
}