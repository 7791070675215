//JS
const LazyLoad = require("vanilla-lazyload");
global.lazyLoad = new LazyLoad();
global.bootstrap = require('bootstrap');
require('es6-promise/auto');
require('@fortawesome/fontawesome-free');
require('../../scripts/App/main');
require('../../scripts/polyfills');
require('../../scripts/analytics-events');
require('../functions/load-modules-dinamically');
require('../../scripts/Header/main');
require('../../scripts/Header/categories');
require('../../scripts/Header/messages');
require('../../scripts/PurchaseGateway/Cart/main');
require('../../scripts/PurchaseGateway/Voucher/main');
require('../../scripts/LeftMenu/old/main');
require('../../scripts/LeftMenu/main');
require('../../scripts/Tools/quantity-selector');
require('../../scripts/Footer/main');
require('../../scripts/User/login');
require('../../scripts/pop-ups-smarter-click');
require('../../scripts/Cookies/main');
require('../../scripts/Country/pop-up-country-select');
require('../../scripts/Product/popup-product');
require('../../scripts/Product/Old/options');
require('../../scripts/Tools/card-collapse');
require('../../scripts/Tools/lazy-background-image');
require('../../scripts/Tools/scroll-top-button');
require('../../scripts/Searcher/main');
require('../../scripts/Promotion/popup');
require('../../scripts/Tools/input-number');
require('../../scripts/MessageSOS/main');
require('../../scripts/User/access');
require('../../scripts/Newsletter/main');
require('../../scripts/Header/breadcrumb');
require('../../scripts/Tools/textarea');
require('../../scripts/Product/popup-image-zoom');
require('../../scripts/Tools/webp-polyfill');
//CSS
require('../../../../sass/website/Libraries/bootstrap.min.css'); //<- CLEAN BOOTSTRAP (PRODUCTION)
//  require('bootstrap/dist/css/bootstrap.min.css'); // <- FULL BOOTSTRAP (DEVELOP)
require('@fortawesome/fontawesome-free/css/solid.min.css');
require('@fortawesome/fontawesome-free/css/regular.min.css');
require('@fortawesome/fontawesome-free/css/brands.min.css');
require('../../../../sass/website/Libraries/font-awesome.min.css'); //<- CLEAN FONTAWESOME (PRODUCTION)
// require('@fortawesome/fontawesome-free/css/fontawesome.min.css'); //<- FULL FONTAWESOME (DEVELOP)
require('@splidejs/splide/dist/css/splide.min.css');
require('../../../../sass/website/general-critic.scss');
require('../../../../sass/website/Header/main-critic.scss');
require('../../../../sass/website/Tools/lazy-background-image.scss');
require('../../../../sass/website/Header/breadcrumb.scss');
require('../../../../sass/website/Product/Page/widget-help.scss');
