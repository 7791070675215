export function renderAmazonPayButton(credentials)
{
    waitApi(function () {
        amazon.Pay.renderButton('#amazon-pay-button', {
            // set checkout environment
            merchantId: credentials.merchantId,
            ledgerCurrency: credentials.ledgerCurrency,
            sandbox: credentials.sandbox,
            checkoutLanguage: credentials.checkoutLanguage,
            productType: credentials.productType,
            placement: credentials.placement,
            buttonColor: credentials.buttonColor,
            // configure Create Checkout Session request
            createCheckoutSessionConfig: {
                payloadJSON: JSON.parse(credentials.createCheckoutSessionConfig.payloadJSON),
                signature: credentials.createCheckoutSessionConfig.signature,
                publicKeyId: credentials.createCheckoutSessionConfig.publicKeyId
            }
        });
    })
}

function waitApi(callback)
{
    setTimeout(function() {
        if (typeof amazon !== 'undefined') {
            callback();
        } else {
            waitApi(callback);
        }
    }, 100);
}



