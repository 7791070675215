import {clickEvent, keyupEvent} from "../../../webpack/modules/events";
import {hideLoadingButton, showLoadingButton} from "../../Tools/loading-button";
import {slideDown, slideUp} from "../../../webpack/modules/element-visibility";
import {applyVoucher, removeVoucher} from "../../../webpack/modules/voucher";
import {ga4TrackSimpleEvent} from "../../../webpack/modules/analytics";
import {renderCart} from "../../../webpack/modules/cart";

keyupEvent('.voucher-code', true, function(element) {
    let code = element.value;
    matchInputs(code);
    updateVoucherButton(code);
});

clickEvent('.voucher-apply', true, function(element) {
    showLoadingButton(element);
    let isCart = element.dataset.cart === 'true';
    let code = document.querySelector('.voucher-code').value;
    let errorElements = document.querySelectorAll('.voucher-error');
    errorElements.forEach((errorElement) => slideUp(errorElement));
    let warningElements = document.querySelectorAll('.voucher-warning');
    warningElements.forEach((warningElement) => slideUp(warningElement));
    if (code) {
        applyVoucher(
            code,
            function(warning) {
                if (warning) {
                    hideLoadingButton(element);
                    warningElements.forEach(function(warningElement) {
                        warningElement.innerHTML = warning;
                        slideDown(warningElement);
                    });
                    setTimeout(function() {
                        if (isCart) renderCart();
                        else location.reload();
                    }, 10000);
                } else {
                    if (isCart) renderCart();
                    else location.reload();
                }
                ga4TrackSimpleEvent(
                    {
                        event: 'checkout',
                        step: 3,
                        method: 'add_cupon'
                    }
                );
            },
            function(error) {
                hideLoadingButton(element);
                errorElements.forEach(function(errorElement) {
                    errorElement.innerHTML = error;
                    slideDown(errorElement);
                });
                ga4TrackSimpleEvent(
                    {
                        event: 'checkout',
                        step: 3,
                        method: 'error_cupon'
                    }
                );
            }
        );
    }
});

clickEvent('.voucher-remove', true, function(element) {
    showLoadingButton(element);
    let isCart = element.dataset.cart === 'true';
    let errorElements = document.querySelectorAll('.voucher-error');
    errorElements.forEach((errorElement) => slideUp(errorElement));
    removeVoucher(
        function() {
            if (isCart) renderCart();
            else location.reload();
        },
        function(error) {
            hideLoadingButton(element);
            errorElements.forEach(function(errorElement) {
                errorElement.innerHTML = error;
                slideDown(errorElement);
            });
            ga4TrackSimpleEvent(
                {
                    event: 'checkout',
                    step: 3,
                    method: 'remove_cupon'
                }
            );
        }
    );
});

function matchInputs(code)
{
    document.querySelectorAll('.voucher-code').forEach((input) => input.value = code);
}

function updateVoucherButton(code)
{
    document.querySelectorAll('.voucher-apply').forEach(function(voucherButton) {
        if (code) {
            voucherButton.classList.remove('btn-outline-purple');
            voucherButton.classList.add('btn-purple');
        } else {
            voucherButton.classList.remove('btn-purple');
            voucherButton.classList.add('btn-outline-purple');
        }
    });
}