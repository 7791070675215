import {hideElement} from "./element-visibility";
import {post} from "./ajax";
import {isMobile} from "./devices";

let ajaxGetSuggestions = null;
export function getElasticSuggestions(searcher, maxSuggestions, callback)
{
    let search = searcher.querySelector('.search').value.toLowerCase();
    let productType = isMobile() || document.querySelector('header') ? '' : searcher.querySelector('.search-type.selected').dataset.suggestionsName;
    let totalSuggestionsRendered = window.lastSearches.length;
    let div = searcher.querySelector('.suggestions');
    let specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialCharacters.test(search) || search.trim().length === 0) {
        hideElement(div);
        return;
    }

    if (totalSuggestionsRendered >= maxSuggestions) callback([]);
    if (ajaxGetSuggestions) ajaxGetSuggestions.abort();

    ajaxGetSuggestions = post(
        '/ajax/website/search/get-suggestions',
        {
            search: search,
            productType: productType
        },
        function(data) {
            if (totalSuggestionsRendered >= maxSuggestions) callback([]);
            callback(data.tags);
            ajaxGetSuggestions = null;
        },
        function () {
            ajaxGetSuggestions = null;
            callback([]);
        }
    )
}