import {clickEvent, submitEvent} from "../../webpack/modules/events";
import {
    closeRecoverPasswordForm, closeRegisterForm,
    openRecoverPasswordForm, openRegisterForm,
    sendLogin,
    sendRecoverPassword,
    sendRegister
} from "../../webpack/modules/access";

submitEvent('.login-form', true, function(form, event) {
    event.preventDefault();
    sendLogin(form);
});

submitEvent('.recover-password-form', true, function(form, event) {
    event.preventDefault();
    sendRecoverPassword(form);
});

submitEvent('.register-form', true, function(form, event) {
    event.preventDefault();
    sendRegister(form);
});

clickEvent('.recover-pass', true, function(element, event) {
    event.preventDefault();
    event.stopPropagation();
    let access = element.closest('.access-content');
    openRecoverPasswordForm(access);
});

clickEvent('.cancel-recover-pass', true, function(element) {
    let access = element.closest('.access-content');
    closeRecoverPasswordForm(access);
});

clickEvent('.user-sign-up', true, function(element) {
    let access = element.closest('.access-content');
    openRegisterForm(access);
});

clickEvent('.cancel-user-sign-up', true, function(element) {
    let access = element.closest('.access-content');
    closeRegisterForm(access);
});