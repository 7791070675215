let urlElement = null;
function init(url) {
    urlElement = document.createElement('a');
    urlElement.href = url;
}

export function getURLPath(url)
{
    init(url);

    return urlElement.pathname;
}

export function getURLSearch(url)
{
    init(url);

    return urlElement.search;
}

export function getURLPathAndSearch(url)
{
    init(url);

    return urlElement.pathname + urlElement.search;
}