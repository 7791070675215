import {get, post} from "../ajax";
import {addItem, modifyItem, removeItem} from "../cart";
import {slideDown, slideUp} from "../element-visibility";
import {fireChangeEvent, fireClickEvent} from "../events";
import {ecAddProduct, dataLayerAddProduct, ecSetAction, trackEvent, sendFacebookConversionsEvent} from "../analytics";
import {openModalFromHtml} from "../modal";
import {productAddToCart} from "../algolia-insights";
import {updateSliderPreviewBackground, updateSliderPreviewImage} from "./product-image-slider";
import {getURLPath} from "../URLUtils";

let ajaxSendCalculatePrice = null;
let timerCalculatePrice = null;
export function calculatePrice(scope)
{
    if (ajaxSendCalculatePrice) hideLoader(scope);
    clearTimeout(timerCalculatePrice);
    timerCalculatePrice = setTimeout(function() {
        let productId = scope.dataset.productId;
        let quantity = getQuantity(scope);
        let options = getOptions(scope);
        let complements = getComplements(scope);
        let crossSellingProducts = getCrossSellingProducts(scope);

        if (validOptions(options)) {
            showLoader(scope);
            ajaxCalculatePrice(
                productId,
                quantity,
                options,
                function(response) {
                    let price = response.price * quantity;
                    complements.forEach(function (complement) {
                        price += parseFloat(complement.price) * parseInt(complement.quantity);
                    });
                    crossSellingProducts.forEach(function(product) {
                        price += parseFloat(product.price) * parseInt(product.quantity);
                    });
                    price = price.toFixed(2);
                    displayPrice(scope, price);
                    let sizeWarningAlert = scope.querySelector('.size-warning');
                    if (response.warning) {
                        sizeWarningAlert.innerHTML = response.warning;
                        slideDown(sizeWarningAlert);
                    } else {
                        slideUp(sizeWarningAlert);
                    }
                    hideLoader(scope);
                },
                function() {
                    hideLoader(scope);
                }
            )
        }
    }, 200);
}

export function ajaxCalculatePrice(productId, quantity, options, success, error)
{
    if (ajaxSendCalculatePrice) ajaxSendCalculatePrice.abort();
    ajaxSendCalculatePrice = post(
        '/ajax/website/product/calculate-price',
        {
            productId: productId,
            quantity: quantity,
            options: options
        },
        function (response) {
            ajaxSendCalculatePrice = null;
            success(response);
        },
        function() {
            ajaxSendCalculatePrice = null;
            error();
        }
    );
}

let ajaxUpdatePackPrices = null;
export function updatePackPrices(scope, productIndex)
{
    if (ajaxUpdatePackPrices) {
        ajaxUpdatePackPrices.abort();
    }
    let productId = scope.dataset.productId;
    let options = getOptions(scope, productIndex);
    let pack = options.pack;

    if (pack) {
        ajaxUpdatePackPrices = post(
            '/ajax/website/product/update-pack-prices',
            {
                productId: productId,
                pack: pack
            },
            function (response) {
                displayPackPrices(scope, productIndex, response.prices);
                ajaxUpdatePackPrices = null;
            },
            function() {
                ajaxUpdatePackPrices = null;
            }
        );
    }
}

function validOptions(options) {
    return validSizeOptions(options) && validPackOption(options);
}

function validSizeOptions(options)
{
    let valid = !(((!options['width'] || !parseFloat(options['width'])) || (!options['height'] || !parseFloat(options['height']))) &&
        !options['device'] &&
        !options['composition'] &&
        !options['model']);

    if (valid && options['multi'] && options['multi'].length) {
        options['multi'].forEach(function (multiProductOptions) {
            valid = validSizeOptions(multiProductOptions);
            if (!valid) {
                return false;
            }
        });
    }

    return valid;
}

function validPackOption(options)
{
    return !('pack' in options && !options['pack']);
}

function getOptions(scope)
{
    let options = [];
    options['multi'] = [];
    scope.querySelectorAll('.product-options').forEach(function (productOptions) {
        let productIndex = productOptions.dataset.index;
        if (productIndex > 1) {
            options['multi'][productIndex] = [];
        }
        productOptions.querySelectorAll('.product-option').forEach(function (option) {
            if (option.classList.contains('has-error')) {
                return;
            }
            switch (option.dataset.type) {
                case 'pack':
                    addPackOptions(options, option, productIndex);
                    break;
                case 'size':
                    addSizeOptions(options, option, productIndex);
                    break;
                case 'customizer':
                    addCustomizeOptions(options, option, productIndex);
                    break;
                case 'effect':
                    addEffectOptions(options, option, productIndex);
                    break;
                case 'model':
                    addModelOptions(options, option, productIndex);
                    break;
                case 'modelSize':
                    addModelSizeOptions(options, option, productIndex);
                    break;
                case 'materialColor':
                    addMaterialColorOptions(options, option, productIndex);
                    break;
                case 'color':
                    addColorOptions(options, option, productIndex);
                    break;
                case 'orientation':
                    addOrientationOptions(options, option, productIndex);
                    break;
                case 'glass-reversed':
                    addGlassReversedOptions(options, option, productIndex);
                    break;
                case 'tissue':
                    addTissueOptions(options, option, productIndex);
                    break;
                case 'mechanism':
                    addMechanismOptions(options, option, productIndex);
                    break;
                case 'drop':
                    addDropOptions(options, option, productIndex);
                    break;
                case 'support-color':
                    addSupportColorOptions(options, option, productIndex);
                    break;
                case 'hidden':
                    addHiddenOptions(options, option, productIndex);
                    break;
            }
        });
    });

    return options;
}

function addPackOptions(options, packOption, productIndex)
{
    let selectedPack = packOption.querySelector('input[type="radio"]:checked');

    if (selectedPack) {
        let pack = selectedPack.value;
        if (pack == 'custom-pack') {
            pack = packOption.querySelector('[name="customPack"]').value;
        }
        if (productIndex == 1) {
            options['pack'] = pack;
        } else {
            options['multi'][productIndex]['pack'] = pack;
        }
    }
}

function addSizeOptions(options, sizeOption, productIndex)
{
    let selectedSize = sizeOption.querySelector('input[name="size"]:checked');

    let width = null;
    let height = null;
    let personalizedSize = false;
    let device = null;
    let composition = null;

    if (selectedSize) {
        switch (selectedSize.value) {
            case 'custom-size':
                width = sizeOption.querySelector('[name="customWidth"]').value;
                height = sizeOption.querySelector('[name="customHeight"]').value;
                personalizedSize = true;
                break;
            case 'both-size':
                width = sizeOption.querySelector('[name="bothWidth"]').value;
                height = sizeOption.querySelector('[name="bothHeight"]').value;
                personalizedSize = true;
                break;
            case 'presets-size':
                let widthInput = sizeOption.querySelector('[name="presetsWidth"]:checked');
                let heightInput = sizeOption.querySelector('[name="presetsHeight"]:checked');
                if (widthInput) {
                    width = widthInput.value;
                }
                if (heightInput) {
                    height = heightInput.value;
                }
                break;
            default:
                if (selectedSize.dataset.device) {
                    device = selectedSize.dataset.device;
                } else if (selectedSize.dataset.composition) {
                    composition = selectedSize.dataset.composition;
                } else {
                    width = selectedSize.dataset.width;
                    height = selectedSize.dataset.height;
                }
                break;
        }
    }

    if (productIndex == 1) {
        options['width'] = width;
        options['height'] = height;
        options['personalizedSize'] = personalizedSize;
        options['device'] = device;
        options['composition'] = composition;
    } else {
        options['multi'][productIndex]['width'] = width;
        options['multi'][productIndex]['height'] = height;
        options['multi'][productIndex]['personalizedSize'] = personalizedSize;
        options['multi'][productIndex]['device'] = device;
        options['multi'][productIndex]['composition'] = composition;
    }
}

function addCustomizeOptions(options, customizeOption, productIndex)
{
    let customImage = customizeOption.querySelector('input[name="customImage"]');
    let customImageText = customizeOption.querySelector('input[name="display-customImage"]');
    let customImageFromWeb = customizeOption.querySelector('.custom-image-from-web');
    let customText = customizeOption.querySelector('textarea[name="customText"], input[name="customText"]');
    let customPriceIncrement = customizeOption.querySelector('input[name="customPriceIncrement"]');
    let personalizedSvg = customizeOption.querySelector('input[name="personalizedSvg"]');
    let personalizedImagePreview = customizeOption.querySelector('input[name="personalizedImagePreview"]');

    if (productIndex == 1) {
        options['personalizedImageFromWeb'] = customImageFromWeb ? customImageFromWeb.checked : false;
        options['personalizedImage'] = customImage ? customImage.value : null;
        options['personalizedImageText'] = customImageText ? customImageText.value : null;
        options['personalizedText'] = customText ? customText.value : null;
        if (customPriceIncrement && customPriceIncrement.checked && (options['personalizedText'] || options['personalizedImage']))
            options['customPriceIncrement'] = true;
        options['personalizedSvg'] = personalizedSvg ? personalizedSvg.value : null;
        options['personalizedImagePreview'] = personalizedImagePreview ? personalizedImagePreview.value : null;
    } else {
        options['multi'][productIndex]['personalizedImageFromWeb'] = customImageFromWeb ? customImageFromWeb.checked : false;
        options['multi'][productIndex]['personalizedImage'] = customImage ? customImage.value : null;
        options['multi'][productIndex]['personalizedImageText'] = customImageText ? customImageText.value : null;
        options['multi'][productIndex]['personalizedText'] = customText ? customText.value : null;
        if (customPriceIncrement && customPriceIncrement.checked && (options['multi'][productIndex]['personalizedText'] || options['multi'][productIndex]['personalizedImage']))
            options['multi'][productIndex]['customPriceIncrement'] = true;
        options['multi'][productIndex]['personalizedSvg'] = personalizedSvg ? personalizedSvg.value : null;
        options['multi'][productIndex]['personalizedImagePreview'] = personalizedImagePreview ? personalizedImagePreview.value : null;
    }
}

function addEffectOptions(options, effectOption, productIndex)
{
    let selectedEffect = effectOption.querySelector('input[name="effect"]:checked');
    if (selectedEffect) {
        if (productIndex == 1) {
            options['effect'] = selectedEffect.value;
        } else {
            options['multi'][productIndex]['effect'] = selectedEffect.value;
        }
    }
}

function addModelOptions(options, genderOption, productIndex)
{
    let selectedModel = genderOption.querySelector('input[name="model"]:checked');
    if (selectedModel) {
        if (productIndex == 1) {
            options['model'] = selectedModel.value;
        } else {
            options['multi'][productIndex]['model'] = selectedModel.value;
        }
    }
}

function addModelSizeOptions(options, genderOption, productIndex)
{
    let selectedModelSize = genderOption.querySelector('input[name="modelSize"]:checked');
    if (selectedModelSize) {
        if (productIndex == 1) {
            options['modelSize'] = selectedModelSize.value;
        } else {
            options['multi'][productIndex]['modelSize'] = selectedModelSize.value;
        }
    }
}

function addMaterialColorOptions(options, materialColorOption, productIndex)
{
    let selectedMaterialColor = materialColorOption.querySelector('input[name="materialColor"]:checked');
    if (selectedMaterialColor) {
        if (productIndex == 1) {
            options['materialColor'] = selectedMaterialColor.value;
        } else {
            options['multi'][productIndex]['materialColor'] = selectedMaterialColor.value;
        }
    }
}

function addColorOptions(options, colorOption, productIndex)
{
    let selectedColor = colorOption.querySelector('input[name="color"]:checked');
    if (selectedColor) {
        if (productIndex == 1) {
            options['color'] = selectedColor.value;
        } else {
            options['multi'][productIndex]['color'] = selectedColor.value;
        }
    }
}

function addOrientationOptions(options, orientationOption, productIndex)
{
    let selectedOrientation = orientationOption.querySelector('input[name="orientation"]:checked');
    if (selectedOrientation) {
        if (productIndex == 1) {
            options['mirrored'] = selectedOrientation.value === 'mirrored';
        } else {
            options['multi'][productIndex]['mirrored'] = selectedOrientation.value === 'mirrored';
        }
    }
}

function addGlassReversedOptions(options, glassReversedOption, productIndex)
{
    let selectedGlassReversed = glassReversedOption.querySelector('input[name="glass-reversed"]:checked');
    if (selectedGlassReversed) {
        if (productIndex == 1) {
            options['reversed'] = true;
        } else {
            options['multi'][productIndex]['reversed'] = true;
        }
    }
}

function addTissueOptions(options, tissueOption, productIndex)
{
    let selectedTissue = tissueOption.querySelector('input[name="tissue"]:checked');
    if (selectedTissue) {
        if (productIndex == 1) {
            options['tissue'] = selectedTissue.value;
        } else {
            options['multi'][productIndex]['tissue'] = selectedTissue.value;
        }
    }
}

function addMechanismOptions(options, mechanismOption, productIndex)
{
    let selectedMechanism = mechanismOption.querySelector('input[name="mechanism"]:checked');
    if (selectedMechanism) {
        let mechanism = selectedMechanism.value;
        let chainPosition = null;
        if (selectedMechanism.dataset.chain == 'true') {
            chainPosition = mechanismOption.querySelector('input[name="chainPosition"]:checked').value;
        }

        if (productIndex == 1) {
            options['mechanism'] = mechanism;
            if (chainPosition) {
                options['chainPosition'] = chainPosition;
            }
        } else {
            options['multi'][productIndex]['mechanism'] = mechanism;
            if (chainPosition) {
                options['multi'][productIndex]['chainPosition'] = chainPosition;
            }
        }
    }
}

function addDropOptions(options, dropOption, productIndex)
{
    let selectedDrop = dropOption.querySelector('input[name="drop"]:checked');
    if (selectedDrop) {
        if (productIndex == 1) {
            options['drop'] = selectedDrop.value;
        } else {
            options['multi'][productIndex]['drop'] = selectedDrop.value;
        }
    }
}

function addSupportColorOptions(options, dropOption, productIndex)
{
    let selectedSupportColor = dropOption.querySelector('input[name="support-color"]:checked');
    if (productIndex == 1) {
        options['supportColor'] = selectedSupportColor ? selectedSupportColor.value : '';
    } else {
        options['multi'][productIndex]['supportColor'] = selectedSupportColor ? selectedSupportColor.value : '';
    }
}

function addHiddenOptions(options, hiddenOptions, productIndex)
{
    hiddenOptions.querySelectorAll('input').forEach(function(input) {
        if (productIndex == 1) {
            options[input.name] = input.value;
        } else {
            options['multi'][productIndex][input.name] = input.value;
        }
    });
}

function getComplements(scope)
{
    let complements = [];
    let productComplements = scope.querySelectorAll('.product-complement:checked');

    productComplements.forEach(function (complement) {
        if (complement.dataset.alreadyAdded) {
            return;
        }
        if (complement.id === 'checkbox-up-selling-personalized-text') {
            complements.push(upSellingPersonalizedTextData(complement));
        } else {
            complements.push(
                {
                    id: complement.value,
                    price: complement.dataset.price,
                    quantity: complement.dataset.quantity,
                    isLocked: complement.dataset.locked,
                    linkToParent: complement.dataset.linkToParent,
                    options: {
                        width: complement.dataset.width,
                        height: complement.dataset.height,
                        device: complement.dataset.device,
                        composition: complement.dataset.composition
                    }
                }
            );
        }
    });

    return complements;
}

function displayPrice(scope, price)
{
    scope.querySelectorAll('.price .price-value').forEach(function (element) {
        element.innerHTML = price;
    });
}

function displayPackPrices(scope, productIndex, prices)
{
    prices.forEach(function (element) {
        let id = element.id;
        let price = element.price;
        let oldPrice = element.oldPrice;
        let labels = element.labels;
        let input = scope.querySelector('.product-options[data-index="'+productIndex+'"] input[name="size"][value="'+id+'"]');
        let label = scope.querySelector('label[for="'+input.id+'"]');

        input.dataset.price = price;
        label.querySelector('.size-price').innerHTML = price;
        if (oldPrice) {
            label.querySelector('.size-old-price').innerHTML = oldPrice;
        }
        label.querySelector('.size-labels').innerHTML = labels;
    });
}

export function addToCart(scope, callback)
{
    hideAllErrors(scope);
    showLoader(scope);
    let data = getDataAddToCart(scope);
    let queryId = document.querySelector('#al-query-id').value;
    let scopeName = scope.dataset.scope;
    let fbqData = {
        content_type: 'product',
        content_name: '',
        content_ids: [],
        contents: [],
        value: 0,
        currency: ''
    };
    addItem(
        data,
        function(items) {
            hideLoader(scope);
            items.forEach(function(item) {
                dataLayerAddProduct(item);
                ecAddProduct(item.productId, item.productName, item.price, item.quantity, item.color, item.size, item.orientation);
                ecSetAction('add');
                if (scopeName == 'product-page') {
                    trackEvent('ProductPage', 'AddToCart', item.productName);
                } else if (scopeName == 'product-popup') {
                    trackEvent('ProductList', 'AddToCart', item.productName);
                }
                if (window.fbq) {
                    if (!fbqData.content_name) {
                        fbqData.content_name = item.productName;
                        fbqData.currency = item.currency;
                    }
                    fbqData.content_ids.push(item.reference);
                    fbqData.value = fbqData.value + (item.price * item.quantity);
                    fbqData.contents.push({id: item.reference, quantity: item.quantity});
                }
                if (window.pintrk) {
                    pintrk('track', 'AddToCart', {
                        value: item.price,
                        order_quantity: item.quantity,
                        currency: item.currency
                    });
                }
                productAddToCart('Product', 'Add To Cart', item.productId.toString(), queryId);
            });
            if (window.fbq) {
                fbq('track', 'AddToCart', fbqData);
                sendFacebookConversionsEvent('AddToCart', fbqData);
            }

            callback();
        },
        function(productIndex, type, message) {
            if (type && type !== '') {
                let productOption = scope.querySelector(`.product-options[data-index="${productIndex}"] .product-option[data-type="${type}"]`);
                if (productOption.classList.contains('collapsed')) {
                    fireClickEvent(productOption.querySelector('.card-header'), true);
                }
                let element = productOption.querySelector(`.option-error`);
                element.innerHTML = message;
                slideDown(element);
                productOption.scrollIntoView({block: "center", behavior: "smooth"});
            }
            hideLoader(scope);
        }
    );
}

export function modifyCartItem(scope, callback)
{
    hideAllErrors(scope);
    showLoader(scope);
    let data = getDataAddToCart(scope);
    let dataToRemove = getDataToRemoveFromCart(scope);

    dataToRemove.forEach(function(item) {
        removeItem(item.dataset.alreadyAdded);
    });

    modifyItem(
        data,
        function(items) {
            hideLoader(scope);
            callback();
        },
        function(productIndex, type, message) {
            if (type && type !== '') {
                let productOption = scope.querySelector(`.product-options[data-index="${productIndex}"] .product-option[data-type="${type}"]`);
                if (productOption.classList.contains('collapsed')) {
                    fireClickEvent(productOption.querySelector('.card-header'), true);
                }
                let element = productOption.querySelector(`.option-error`);
                element.innerHTML = message;
                slideDown(element);
                productOption.scrollIntoView({block: "center", behavior: "smooth"});
            }
            hideLoader(scope);
        }
    );
}

function getDataAddToCart(scope)
{
    let productId = scope.dataset.productId;
    let quantity = getQuantity(scope);
    let options = getOptions(scope);
    let complements = getComplements(scope);
    let crossSellingProducts = getCrossSellingProducts(scope);

    return {
        productId: productId,
        quantity: quantity,
        options: options,
        extraProducts: complements.concat(crossSellingProducts)
    }
}

function getDataToRemoveFromCart(scope)
{
    let complements = getPreviouslyAddedComplementsToRemove(scope);
    let crossSellingProducts = getPreviouslyAddedCrossSellingProductsToRemove(scope);

    return complements.concat(crossSellingProducts);
}

function getQuantity(scope)
{
    let input = scope.querySelector('input[name="quantity"]');

    return input ? input.value : 1;
}

function hideAllErrors(scope)
{
    let elements = scope.querySelectorAll('.product-option .option-error');
    elements.forEach(function(element) {
        element.innerHTML = '';
        slideUp(element);
    });
}

export function resetOptions(scope)
{
    let optionsInputs = scope.querySelectorAll('.product-option input');

    optionsInputs.forEach(function(input) {
        let type = input.type;
        let isDefaultValue = input.classList.contains('default-value');
        let isNotResettable = input.dataset.notResettable;
        let noFireChangeEvent = input.classList.contains('no-fire-change-event');
        if (isDefaultValue && (type === 'radio' || type === 'checkbox')) {
            input.checked = true;
        } else if (!isNotResettable) {
            switch (type) {
                case 'radio':
                case 'checkbox':
                    input.checked = false;
                    break;
                case 'number':
                case 'text':
                case 'hidden':
                case 'file':
                    input.value = '';
                    break;
            }
        }
        
        if (!noFireChangeEvent) fireChangeEvent(input, true);
    });
}

function getPreviouslyAddedComplementsToRemove(scope)
{
    let productComplements = scope.querySelectorAll('.product-complement');
    let toRemove = [].filter.call(productComplements, function(el) {
        return !el.checked && el.dataset.alreadyAdded;
    });

    return toRemove;
}

function getPreviouslyAddedCrossSellingProductsToRemove(scope)
{
    let crossSellingProducts = scope.querySelectorAll('input[name="cross-selling-product"]');
    let toRemove = [].filter.call(crossSellingProducts, function(el) {
        return !el.checked && el.dataset.alreadyAdded;
    });

    return toRemove;
}

function getCrossSellingProducts(scope)
{
    let products = [];
    let crossSellingProducts = scope.querySelectorAll('input[name="cross-selling-product"]:checked');

    crossSellingProducts.forEach(function (product) {
        if (product.dataset.alreadyAdded) {
            return;
        }

        products.push(
            {
                id: product.value,
                price: product.dataset.price,
                quantity: product.dataset.quantity,
                linkToParent: product.dataset.linkToParent,
                options: {
                    width: product.dataset.width,
                    height: product.dataset.height,
                    device: product.dataset.device,
                    composition: product.dataset.composition,
                    originalProduct: product.dataset.originalProduct,
                    color: product.dataset.color,
                    personalizedImage: product.dataset.image,
                    pack: product.dataset.pack
                }
            }
        );
    });

    return products;
}

function showLoader(scope)
{
    scope.querySelectorAll('.btn-add-to-cart').forEach(function(element) {
        element.disabled = true;
        let loader = element.querySelector('.loader');
        let elementToHide = element.querySelector('.text');
        elementToHide.classList.add('d-none');
        loader.classList.remove('d-none');
    });
}

function hideLoader(scope)
{
    scope.querySelectorAll('.btn-add-to-cart').forEach(function(element) {
        element.disabled = false;
        let loader = element.querySelector('.loader');
        let elementToHide = element.querySelector('.text');
        loader.classList.add('d-none');
        elementToHide.classList.remove('d-none');
    });
}

export function loadOptionInfo(type, productId, model)
{
    get(
        '/ajax/website/product/load-option-info',
        {
            type: type,
            productId: productId,
            modelId: model,
        },
        function (response) {
            openModalFromHtml(response.modal);
        }
    );
}

export function roundToMultiple(input)
{
    let alert = input.closest('.product-option').querySelector('.multiple-size-change');
    alert.classList.add('d-none');

    let multiple = parseFloat(input.dataset.multiple);
    let value = parseFloat(input.value);
    let roundedValue = parseFloat((Math.ceil(value/multiple)*multiple).toFixed(2));

    if (roundedValue !== value && roundedValue > 0) {
        input.value = roundedValue;
        let text = alert.dataset.text.replace('XX', roundedValue);
        alert.querySelector('p').innerHTML = text;
        alert.classList.remove('d-none');
    }
}

export function checkSizeLimits(productOption)
{
    let hasMinSizeError = false;
    let hasMaxSizeError = false;
    let minSizeError = productOption.querySelector('.min-size-error');
    let maxSizeError = productOption.querySelector('.max-size-error');
    let selectedOption = productOption.querySelector('input[type="radio"]:checked');
    if (selectedOption && (selectedOption.value == 'custom-size' || selectedOption.value == 'both-size')) {
        productOption.querySelectorAll('input[data-min]').forEach(function(input) {
            if (parseFloat(input.value) < parseFloat(input.dataset.min)) {
                hasMinSizeError = true;
            }
        });
        productOption.querySelectorAll('input[data-max]').forEach(function(input) {
            if (parseFloat(input.value) > parseFloat(input.dataset.max)) {
                hasMaxSizeError = true;
            }
        });
    }

    if (hasMinSizeError || hasMaxSizeError) {
        productOption.classList.add('has-error');
        if (hasMinSizeError) {
            slideDown(minSizeError);
        } else {
            slideUp(minSizeError);
        }
        if (hasMaxSizeError) {
            slideDown(maxSizeError);
        } else {
            slideUp(maxSizeError);
        }
    } else {
        productOption.classList.remove('has-error');
        slideUp(minSizeError);
        if (maxSizeError) slideUp(maxSizeError);
    }
}

let ajaxPreviewImage = null;
let timerPreviewImage = null;
export function loadPreviewImage(scope, productIndex, photomontage)
{
    if (ajaxPreviewImage) {
        ajaxPreviewImage.abort();
    }
    clearTimeout(timerPreviewImage);
    timerPreviewImage = setTimeout(function() {
        let productId = scope.dataset.productId;
        let options = getOptions(scope, productIndex);

        if (photomontage && !options['composition']) {
            return;
        }

        ajaxPreviewImage = post(
            '/ajax/website/product/load-preview-image',
            {
                productId: productId,
                options: options,
                photomontage: photomontage,
                productIndex: productIndex,
            },
            function (response) {
                if (scope.dataset.scope == 'product-page') {
                    updateSliderPreviewImage(productIndex, response.image);
                }
                let compositionPreview = scope.querySelector('input[name="compositionPreview"]');
                if (compositionPreview) {
                    compositionPreview.value = getURLPath(response.image);
                }

                ajaxPreviewImage = null;
            },
            function() {
                ajaxPreviewImage = null;
            }
        );
    }, 200);
}

export function showGenderModels(scope, productIndex)
{
    let productOptions = scope.querySelector('.product-options[data-index="'+productIndex+'"]');
    let selectedGender = productOptions.querySelector('input[name="gender"]:checked');
    if (selectedGender) {
        let modelOption = productOptions.querySelector(`.product-option[data-type="model"]`);
        let gender = selectedGender.value;
        let previousModels = modelOption.querySelector('.gender-models:not(.d-none)');
        let models = modelOption.querySelector('.gender-models[data-gender="'+gender+'"]');
        let model = models.querySelectorAll('input[name="model"]')[0];
        let optionBody = models.closest('.card-body');

        slideUp(optionBody, 200, function() {
            if (previousModels) {
                previousModels.classList.add('d-none');
            }
            models.classList.remove('d-none');
            model.checked = true;
            fireChangeEvent(model, true);
            if (!modelOption.classList.contains('collapsed')) {
                slideDown(optionBody);
            }
        });
    }
}

export function showModelSizes(scope, productIndex)
{
    let productOptions = scope.querySelector('.product-options[data-index="'+productIndex+'"]');
    let selectedModel = productOptions.querySelector('input[name="model"]:checked');
    if (selectedModel) {
        let sizeOption = productOptions.querySelector(`.product-option[data-type="modelSize"]`);
        let previousSizes = sizeOption.querySelector('.model-sizes:not(.d-none)');
        let previousSize = sizeOption.querySelector('input[name="modelSize"]:checked');
        let model = selectedModel.value;
        let sizes = sizeOption.querySelector('.model-sizes[data-model="'+model+'"]');
        let optionBody = sizes.closest('.card-body');

        slideUp(optionBody, 200, function() {
            if (previousSizes) {
                previousSizes.classList.add('d-none');
            }
            sizes.classList.remove('d-none');
            if (previousSize) {
                previousSize.checked = false;
                fireChangeEvent(previousSize, true);
            }
            if (!sizeOption.classList.contains('collapsed')) {
                slideDown(optionBody);
            }
        });
    }
}

export function updateMaterialColors(scope, productIndex)
{
    let productOptions = scope.querySelector('.product-options[data-index="'+productIndex+'"]');
    let selectedModel = productOptions.querySelector('input[name="model"]:checked');
    if (selectedModel) {
        let fixedMaterialColors = selectedModel.dataset.fixedMaterialColors;

        if (fixedMaterialColors) {
            fixedMaterialColors = fixedMaterialColors.split(',');
        }

        productOptions.querySelectorAll('input[name="materialColor"]').forEach(function(element) {
            element.disabled = false;
            if (fixedMaterialColors && !fixedMaterialColors.includes(element.value)) {
                if (element.checked) {
                    element.checked = false;
                    fireChangeEvent(element, true);
                }
                element.disabled = true;
            }
        });
    }
}

export function showMaterialColor(scope, productIndex)
{
    let productOptions = scope.querySelector('.product-options[data-index="'+productIndex+'"]');
    let selectedMaterialColor = productOptions.querySelector('input[name="materialColor"]:checked');
    if (selectedMaterialColor && scope.dataset.scope == 'product-page') {
        updateSliderPreviewBackground(productIndex, selectedMaterialColor.dataset.previewColor);
    }
}

export function previewScopeOptions(scope)
{
    if (!scope) {
        scope = document.querySelector('#product-page');
    }
    scope.querySelectorAll('.product-option').forEach(function (productOption) {
        previewOption(productOption);
    });
}

export function previewOption(productOption) {
    let type = productOption.dataset.type;
    let optionPreview = productOption.querySelector('.option-preview');
    if (!optionPreview) {
        return;
    }

    let width = null;
    let height = null;
    let preview = '';
    switch (type) {
        case 'size':
            let selectedSize = productOption.querySelector('input[name="size"]:checked');
            if (selectedSize) {
                let um = productOption.querySelector('input[name="currentUM"]').value;
                switch (selectedSize.value) {
                    case 'custom-size':
                        width = productOption.querySelector('input[name="customWidth"]').value;
                        height = productOption.querySelector('input[name="customHeight"]').value;
                        if (width && height) {
                            preview = width+' '+um+' x '+height+' '+um;
                        }
                        break;
                    case 'both-size':
                        width = productOption.querySelector('input[name="bothWidth"]').value;
                        height = productOption.querySelector('input[name="bothHeight"]').value;
                        if (width && height) {
                            preview = width+' '+um+' x '+height+' '+um;
                        }
                        break;
                    case 'presets-size':
                        width = productOption.querySelector('input[name="presetsWidth"]:checked');
                        height = productOption.querySelector('input[name="presetsHeight"]:checked');
                        if (width && height) {
                            preview = width.value+' '+um+' x '+height.value+' '+um;
                        }
                        break;
                    default:
                        preview = selectedSize.dataset.previewText;
                        break;
                }
            }
            break;
        case 'customizer':
            let customImage = productOption.querySelector('input[name="display-customImage"]');
            let customImageFromWeb = productOption.querySelector('input[name="customImageFromWeb"]');
            if ((customImage && customImage.value) || (customImageFromWeb && customImageFromWeb.checked)) {
                preview = '<i class="fa-solid fa-camera me-1"></i>';
            }
            let customText = productOption.querySelector('input[name="customText"]');
            if (customText && customText.value) {
                preview += customText.value;
            }
            break;
        case 'materialColor':
        //nobreak;
        case 'color':
            let selectedColor = productOption.querySelector('input[type="radio"]:checked');
            if (selectedColor) {
                let previewColor = selectedColor.dataset.previewColor;
                let previewText = selectedColor.dataset.previewText;
                preview = '<div class="preview-color" style="background: '+previewColor+'" title="'+previewText+'"></div>';
            }
            break;
        case 'mechanism':
            let selectedMechanism = productOption.querySelector('input[name="mechanism"]:checked');
            if (selectedMechanism) {
                preview = selectedMechanism.dataset.previewText;
            }
            break;
        default:
            let selectedOption = productOption.querySelector('input[type="radio"]:checked');
            if (selectedOption) {
                preview = selectedOption.dataset.previewText;
            }
            break;
    }

    optionPreview.innerHTML = preview;
}


let ajaxProportionalHeight = null;
let timerProportionalHeight = null;
export function getProportionalHeight(widthInput)
{
    if (ajaxProportionalHeight) {
        ajaxProportionalHeight.abort();
    }
    clearTimeout(timerProportionalHeight);
    timerProportionalHeight = setTimeout(function() {
        let width = widthInput.value;
        let productId = widthInput.dataset.productId;
        let productOption = widthInput.closest('.product-option');
        let scope = productOption.closest('.product-scope');
        let factor = null;
        let factorInput = scope.querySelector('input[name="factor"]');
        if (factorInput) factor = factorInput.value;
        let heightDisplay = productOption.querySelector('.custom-height');
        let customHeight = productOption.querySelector('input[name="customHeight"]');
        let selectedOption = productOption.querySelector('input[type="radio"]:checked');
        let errorAlert = productOption.querySelector('.alert.error');

        customHeight.value = 0;
        heightDisplay.innerHTML = 0;
        slideUp(errorAlert);
        if (width) {
            ajaxProportionalHeight = post(
                '/ajax/website/product/get-proportional-height',
                {
                    width: width,
                    productId: productId,
                    factor: factor,
                },
                function (response) {
                    customHeight.value = response.height;
                    heightDisplay.innerHTML = response.height;
                    previewOption(productOption);
                    calculatePrice(scope);
                },
                function (response) {
                    errorAlert.innerHTML = response.sizesMessage;
                    slideDown(errorAlert);
                }
            );
        }
    }, 200);
}

export function updateMechanismFeatures(scope, productIndex)
{
    let productOptions = scope.querySelector('.product-options[data-index="'+productIndex+'"]');
    let selectedMechanism = productOptions.querySelector('input[name="mechanism"]:checked');
    let controlWarning = productOptions.querySelector('.control-warning');
    let chainPosition = productOptions.querySelector('.chain-position');
    let addControl = productOptions.querySelector('.add-control');
    let addCharger = productOptions.querySelector('.add-charger');
    let controlComplement = addControl.querySelector('.mechanism-control');
    let chargerComplement = addCharger.querySelector('.mechanism-charger');
    let controlQuantity = addControl.querySelector('input[name="controlQuantity"]');
    let chargerQuantity = addCharger.querySelector('input[name="chargerQuantity"]');

    if (controlComplement) {
        controlComplement.checked = false;
    }
    if (chargerComplement) {
        chargerComplement.checked = false;
    }
    if (selectedMechanism) {
        if (controlComplement) {
            if (selectedMechanism.dataset.control == 'true') {
                slideDown(addControl);
                slideDown(controlWarning);
                if (controlQuantity) {
                    controlQuantity = parseInt(controlQuantity.value);
                    if (controlQuantity >= 1) {
                        controlComplement.dataset.quantity = controlQuantity;
                        controlComplement.checked = true;
                    }
                }
            } else {
                slideUp(addControl);
            }
        }
        if (chargerComplement) {
            if (selectedMechanism.dataset.charger == 'true') {
                slideDown(addCharger);
                slideDown(controlWarning);
                if (chargerQuantity) {
                    chargerQuantity = parseInt(chargerQuantity.value);
                    if (chargerQuantity >= 1) {
                        chargerComplement.dataset.quantity = chargerQuantity;
                        chargerComplement.checked = true;
                    }
                }
            } else {
                slideUp(addCharger);
            }
        }
        if (selectedMechanism.dataset.control != 'true' && selectedMechanism.dataset.charger != 'true') {
            slideUp(controlWarning);
        }
        if (selectedMechanism.dataset.chain == 'true') {
            slideDown(chainPosition);
        } else {
            slideUp(chainPosition);
        }

        productOptions.querySelectorAll('.mechanism-features-text').forEach(function(element) {
            if (element.dataset.mechanism == selectedMechanism.value) {
                slideDown(element);
            } else {
                slideUp(element);
            }
        });
    }
}

function upSellingPersonalizedTextData(element)
{
    return {
        id: element.value,
        price: element.dataset.price,
        quantity: element.dataset.quantity,
        linkToParent: element.dataset.linkToParent,
        options: {
            width: element.dataset.width,
            height: element.dataset.height,
            personalizedText: element.dataset.text,
            font: element.dataset.font,
            mirrored: element.dataset.mirrored,
            color: element.dataset.color,
            personalizedSvg: element.dataset.svg,
            rightToLeft: element.dataset.rightToLeft,
            isUpselling: true,
            personalizedSize: true
        }
    }
}