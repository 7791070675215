import {clickEvent, submitEvent} from "../../webpack/modules/events";
import {slideUpBySelector} from "../../webpack/modules/element-visibility";
import {loginForm, recoveryPasswordForm, registerForm} from "../../webpack/modules/login";
import {post} from "../../webpack/modules/ajax";

submitEvent('.home .login-form', true, function(form, event) {
    event.preventDefault();
    slideUpBySelector('.login-form .success-messages, .login-form .errors');
    loginForm(form);
});

submitEvent('#header .recovery-password-form', true, function(form, event) {
    event.preventDefault();
    let header =  document.querySelector('#header .login-form .success-messages');
    recoveryPasswordForm(form, header);
});

submitEvent('.modal .recovery-password-form', true, function(form, event) {
    event.preventDefault();
    let modal = document.querySelector('.modal .login-form .success-messages');
    recoveryPasswordForm(form, modal);
});

clickEvent('.showRecoverPass', true, function () {
    document.querySelector('.loginForm').classList.add('d-none');
    document.querySelector('.recoverPass').classList.remove('d-none');
});

submitEvent('.home .register-form', true, function(form, event) {
    event.preventDefault();
    slideUpBySelector('.login-form .success-messages, .login-form .errors');
    registerForm(form);
});

clickEvent('.sendRecoverClose', true, function () {
    document.querySelector('.loginForm').classList.remove('d-none');
    document.querySelector('.recoverPass').classList.add('d-none');
    document.querySelector('.title-login').classList.add('d-none');
});

clickEvent('.showRegister', true, function () {
    document.querySelector('.loginForm').classList.add('d-none');
    document.querySelector('.title-login').classList.add('d-none');
    document.querySelector('.registerForm').classList.remove('d-none');
});

clickEvent('.backLogin', true, function () {
    document.querySelector('.loginForm').classList.remove('d-none');
    document.querySelector('.title-login').classList.remove('d-none');
    document.querySelector('.registerForm').classList.add('d-none');
});

