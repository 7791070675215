import {changeEvent, clickEvent, mouseUpEvent} from "../webpack/modules/events";
import {
    trackEvent,
    ecSetAction,
    ecAddProduct,
    ga4TrackEvent,
    ga4TrackSimpleEvent,
    ga4TrackLinkEvent
} from "../webpack/modules/analytics";

clickEvent('.analytics-track-event', true, function(element) {
    let category = element.dataset.analyticsCategory;
    let action = element.dataset.analyticsAction;
    let label = element.dataset.analyticsLabel;
    let ecDataList = element.dataset.ecList ? element.dataset.ecList : null;
    let ecDataRef = element.dataset.ecRef ? element.dataset.ecRef : null;
    if (ecDataList && ecDataRef) {
        ecAddProduct(ecDataRef, label);
        ecSetAction('click', {list: ecDataList});
    }
    trackEvent(category, action, label);
    ga4TrackEvent(element);
});

/** Track link click */
mouseUpEvent('.ga4-track-link', true, function(element) {
    ga4TrackLinkEvent(element);
});
clickEvent('.ga4-track-link-click', true, function(element) {
    ga4TrackLinkEvent(element);
});

changeEvent('.analytics-track-event, .analytics-track-change-event', true, function(element) {
    let category = element.dataset.analyticsCategory;
    let action = element.dataset.analyticsAction;
    let label = element.dataset.analyticsLabel;
    trackEvent(category, action, label);
    ga4TrackEvent(element);
});

clickEvent('.ga4-event', true, function(element) {
    let event = element.dataset.gaEvent;
    let method = element.dataset.gaMethod || null;
    ga4TrackSimpleEvent(
        {
            event: event,
            method: method
        }
    );
});

changeEvent('.ga4-event', true, function(element) {
    let event = element.dataset.gaEvent;
    let method = element.dataset.gaMethod || null;
    ga4TrackSimpleEvent(
        {
            event: event,
            method: method
        }
    );
});