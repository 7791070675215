import {trackEvent, sendPopupEvent} from "../../library/analytics";
import {clickEvent, customEvent} from "../webpack/modules/events";
import {approvePopUp, showDesktopPopUp, showPopUp} from "../webpack/modules/pop-ups-smarter-click";
import {getDeviceType} from "../webpack/modules/devices";

let device = getDeviceType();

clickEvent('#approve-pop-up-smarter-click', true, function(element) {
    approvePopUp();
    //trackEvent('pop-up', 'exit-page', 'approve');
    let popupAction = element.dataset.popupType || 'pop-up';
    trackEvent('Popup', popupAction, 'approve');
    sendPopupEvent('popup_discount', popupAction, 'approve');
});

clickEvent('.close-pop-up-smarter-click', true, function(element) {
    //trackEvent('pop-up', 'exit-page', 'cancel');
    let popupAction = element.dataset.popupType || 'pop-up';
    trackEvent('Popup', popupAction, 'cancel');
    sendPopupEvent('popup_discount', popupAction, 'cancel');

});

clickEvent('#modal-pop-up-smarter-click .form-check', true, function(element) {
     let checkbox = element.querySelector('input[type="checkbox"]');
     checkbox.checked = !checkbox.checked;
});

customEvent('hidden.bs.modal', '#modal-pop-up-smarter-click', true, function() {
    document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove());
});

setTimeout(function () {
    if (document.querySelector('#purchase-gateway-page')) return;

    document.addEventListener('mouseleave', function(event) {
        showDesktopPopUp(event);
    });

    if (device === 'tablet' || device === 'mobile') {
        idleUser();
        window.addEventListener('blur', function(){
            showPopUp();
        });
    }
}, 30000); // wait 30s before show any popup for the first time

function idleUser() {
    let t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;
    window.onkeydown = resetTimer;
    window.addEventListener('scroll', resetTimer, true);

    function popupFunction() {
        showPopUp();
        leaveDetected = true;
        window.removeEventListener('beforeunload', exitIntentBeforeUnload);
    }

    function resetTimer() {
        clearTimeout(t);
        if (!leaveDetected) {
            t = setTimeout(popupFunction, 180000);
        }
    }
}

let leaveDetected = false;