import {post} from "./ajax";

export function serialize(form)
{
    let data = {};
    let formData = new FormData(form);
    for (let [key, value] of formData) {
        data[key] = value;
    }

    return data;
}

export function sendForm(form, success, error)
{
    let url = form.getAttribute('action');
    let data = serialize(form);
    post(
        url,
        data,
        success,
        error
    )
}

export function addDataToForm(form, data)
{
    let keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
        let name = keys[i];
        let value = data[name];

        form.elements.forEach(function(formInput) {
            if (formInput.name === name) formInput.parentNode.removeChild(formInput);
        });

        let input = document.createElement('input');
        input.setAttribute('name', name);
        input.setAttribute('value', value);
        input.setAttribute('type', 'hidden');

        form.appendChild(input);
    }

    return form;
}