import {clickEvent, submitEvent} from "../../../webpack/modules/events";
import {removeOverlay, renderOverlay} from "../../../webpack/modules/overlay";
import {getCountryDropdown} from "../../../webpack/modules/left-menu-old";
import {hideElement, showElement, slideDown, slideUp} from "../../../webpack/modules/element-visibility";
import {isIOS} from "../../../webpack/modules/devices";
import {trackEvent} from "../../../webpack/modules/analytics";
import {initImageClaimsSlider} from "../../../webpack/modules/image-claims-slider";

let transitionInProgress = false;

window.onresize = resizeLeftMenu;

document.addEventListener( 'DOMContentLoaded', function () {
    initImageClaimsSlider();
});

clickEvent('.left-menu-change-visibility', false, function(element) {
    let action = element.dataset.action;
    let leftMenu = document.querySelector('.left-menu');
    if (action === 'show') {
        resizeLeftMenu();
        renderOverlay(document.body, 'append');
        leftMenu.classList.add('visible');
    } else if (action === 'hide') {
        removeOverlay(document.body);
        leftMenu.classList.remove('visible');
    }
});

clickEvent('.overlay', true, function() {
    let leftMenu = document.querySelector('.left-menu');
    if (leftMenu && leftMenu.classList.contains('visible')) {
        removeOverlay(document.body);
        leftMenu.classList.remove('visible');
    }
});

clickEvent('.left-menu .countries-dropdown', false, function(element, event){
    let target = event.target;
    if (target.classList.contains('close') || target.closest(".close")) return;
    let content = element.querySelector('.content');
    let hasContent = content.childNodes.length;

    if (hasContent) {
        showElement(content);
        resizeLeftMenu();
    } else {
        getCountryDropdown(function(view) {
            content.innerHTML = view;
            showElement(content);
            resizeLeftMenu();
        });
    }
    trackEvent('Menu', 'Opened', 'CountrySelector');
});

clickEvent('.left-menu .countries-dropdown .close', true, function(element, event) {
    let div = element.closest('.countries-dropdown').querySelector('.content');
    hideElement(div);
});

clickEvent('.left-menu .open-categories', false, function(element) {
    if (transitionInProgress) return;
    transitionInProgress = true;
    let leftMenu = element.closest('.left-menu');
    let menu = leftMenu.querySelector('.menu');
    let searcherPlaceholder = element.dataset.searcherPlaceholder;
    let divId = element.dataset.div;
    let div = leftMenu.querySelector('#'+divId);
    if (searcherPlaceholder) {
        leftMenu.querySelector('.search-form input').placeholder = searcherPlaceholder;
    }
    showElement(div);
    slideUp(menu, 900, function() {
        transitionInProgress = false;
    });
});

clickEvent('.left-menu .close-categories', false, function(element) {
    if (transitionInProgress) return;
    transitionInProgress = true;
    let leftMenu = element.closest('.left-menu');
    let body = leftMenu.querySelector('.body');
    let menu = leftMenu.querySelector('.menu');
    let searcher = leftMenu.querySelector('.search-form input');
    let categoriesDiv = element.closest('.categories');
    searcher.placeholder = searcher.dataset.originalPlaceholder;
    body.classList.remove('overflow-auto');
    slideDown(menu, 900, function() {
        hideElement(categoriesDiv);
        body.classList.add('overflow-auto');
        transitionInProgress = false;
    });
});

submitEvent('.left-menu .search-form', false, function() {
    trackEvent('Menu', 'Opened', 'Search');
});

clickEvent('.left-menu .open-personalized-blocks', true, function() {
    openLeftMenu();
});

clickEvent('.left-menu .close-personalized-blocks', true, function() {
    closeLeftMenu();
});

clickEvent('.left-menu .redirect-to-home', false, function(element, event) {
    event.preventDefault();
    let isHomePage = element.dataset.isHomepage === 'true';

    if (isHomePage) {
        closeLeftMenu();
    } else {
        window.location.href = element.href;
    }
});

clickEvent('.left-menu .close-personalized-links, .left-menu .open-personalized-links', true, function(element) {
    let isOpened = element.classList.contains('close-personalized-links');
    let personalizedLinks = document.querySelector('.left-menu .personalized-links');
    if (isOpened) {
        slideUp(personalizedLinks);
        element.classList.remove('close-personalized-links');
        element.classList.add('open-personalized-links');
    } else {
        slideDown(personalizedLinks);
        element.classList.remove('open-personalized-links');
        element.classList.add('close-personalized-links');
    }
});

clickEvent('.left-menu .dropdown .header', true, function(element) {
    let body = element.closest('.dropdown').querySelector('.body');
    let isShow = body.offsetHeight;
    if (isShow) {
        slideUp(body);
    } else {
        slideDown(body);
    }
});

function openLeftMenu()
{
    if (transitionInProgress) return;
    transitionInProgress = true;
    let menu = document.querySelector('.left-menu .menu');
    let personalizedBlocks = document.querySelector('.left-menu .personalized-blocks');
    showElement(personalizedBlocks);
    slideUp(menu, 900, function() {
        transitionInProgress = false;
    });
}

function closeLeftMenu()
{
    if (transitionInProgress) return;
    transitionInProgress = true;
    let menu = document.querySelector('.left-menu .menu');
    let personalizedBlocks = document.querySelector('.left-menu .personalized-blocks');
    let body = document.querySelector('.left-menu .body');
    body.classList.remove('overflow-auto');
    slideDown(menu, 900, function() {
        hideElement(personalizedBlocks);
        body.classList.add('overflow-auto');
        transitionInProgress = false;
    });
}

function resizeLeftMenu()
{
    /*let leftMenu = document.querySelector('.left-menu');
    let loyaltyHeight = leftMenu.querySelector('.loyalty').offsetHeight;
    let screenHeight = (typeof window.outerHeight != 'undefined') ? Math.max(window.outerHeight, window.innerHeight) : window.innerHeight;
    let headerHeight = leftMenu.querySelector('.header').offsetHeight;
    let footerHeight = leftMenu.querySelector('.footer').offsetHeight;
    if (isIOS) screenHeight = window.innerHeight;
    leftMenu.querySelector('.countries-dropdown .content').style.marginTop = loyaltyHeight+'px';
    leftMenu.querySelector('.body').style.maxHeight = (screenHeight - headerHeight - footerHeight)+'px';*/
}