import {clickEvent} from "../../webpack/modules/events";
import {slideDown, slideUp, slideUpBySelector} from "../../webpack/modules/element-visibility";
import {ga4TrackSimpleEvent, trackEvent} from "../../webpack/modules/analytics";
import {initDesktopSlider, initMobileSlider} from "../../webpack/modules/header-categories";
import {isIOS} from "../../webpack/modules/devices";

initSliders();
resizeMobileCategories();

clickEvent('.open-categories', false, function(element) {
    openCategories(element.dataset.div, '.categories');
    let event = element.dataset.analyticsEvent;
    let pt = element.dataset.productTypeName;
    let ptUrl = element.dataset.productTypeUrl;
    if (event){
        trackEvent('Header', event, 'open/close');
        ga4TrackSimpleEvent({
            'event': 'header',
            'link_id': event,
            'link_text': pt,
            'link_url': ptUrl
        });
    }
});

clickEvent('.link-categories', false, function(element) {
    let event = element.dataset.analyticsEvent;
    let pt = element.dataset.productTypeName;
    let ptUrl = element.dataset.productTypeUrl;
    if (event){
        trackEvent('Header', event, 'open/close');
        ga4TrackSimpleEvent({
            'event': 'header',
            'link_id': event,
            'link_text': pt,
            'link_url': ptUrl
        });
    }
});

clickEvent('.open-sub-categories', false, function(element) {
    openCategories(element.dataset.div, '.sub-categories');
});

clickEvent('.close-categories', false, function(element) {
    let div = element.closest('.categories');
    unselectAllCategories();
    slideUp(div);
});

function openCategories(divClass, selector)
{
    let elementOnHeader = document.querySelector(`.open-categories[data-div=${divClass}]`);
    let div = document.querySelector(selector+'.'+divClass);
    let isHide = !div.offsetHeight;
    unselectAllCategories();
    slideUpBySelector(selector, 200, function() {
        if (isHide) {
            slideDown(div);
            elementOnHeader.classList.add('selected')
        }
    });
}

document.addEventListener('click', function(e) {
    let target = e.target;
    let categoryShowed = getCategoryShowed();
    if (categoryShowed && !target.closest('.categories') && !target.classList.contains('categories')) {
        let event = categoryShowed.dataset.analyticsEvent;
        if (event) trackEvent('Header', event, 'open/close');
        slideUp(categoryShowed);
    }
});

function getCategoryShowed()
{
    let category = null;

    document.querySelectorAll('#header .categories').forEach(function(element) {
        let width = element.offsetWidth;
        let height = element.offsetHeight;
        if (width && height) {
            category = element;
        }
    });

    return category;
}

function initSliders()
{
    initDesktopSlider();
    initMobileSlider();
}

function resizeMobileCategories()
{
    if (!document.querySelector('#header')) return;
    let screenHeight = (typeof window.outerHeight != 'undefined') ? Math.max(window.outerHeight, window.innerHeight) : window.innerHeight;
    if (isIOS) screenHeight = window.innerHeight;
    let headerHeight = document.querySelector('#header').offsetHeight;
    let categories = document.querySelectorAll('#header-bottom .categories-mobile-dropdown');
    categories.forEach(function (category) {
        category.style.maxHeight = ((screenHeight - headerHeight) / 1.25) + 'px';
    });
}

function unselectAllCategories()
{
    let categories = document.querySelectorAll('#header-bottom .open-categories');
    categories.forEach(function(category) {
        category.classList.remove('selected');
    });
}