import {
    changeEvent,
    clickEvent,
    customEvent,
} from "../../../webpack/modules/events";
import {
    addItem,
    loadModifyItem,
    removeItem,
    renderCart,
    renderLoader,
    showShareCartLink,
    updateItemQuantity,
    updateItemsNumber,
    updateCustomText
} from "../../../webpack/modules/cart";
import {
    dataLayerAddProduct,
    ecAddProduct,
    ecSetAction, ga4TrackSimpleEvent,
    sendFacebookConversionsEvent,
    trackEvent
} from "../../../webpack/modules/analytics";
import {hideLoadingButton, showLoadingButton} from "../../Tools/loading-button";
import {setCookieForADay} from "../../../../library/cookies";

document.addEventListener("DOMContentLoaded", function() {
    customEvent('shown.bs.modal', '#modal-product', true, function(modal) {
        updateCustomText(modal);
    });
});

changeEvent('#cart .quantity', true, function(element) {
    let quantity = element.value > 0 ? element.value : 1;
    let item = element.closest('.cart-item');
    updateQuantity(item, quantity);
});

clickEvent('#cart .complement .add-to-cart', true, function(element) {
    showLoadingButton(element);
    let complement = element.closest('.complement');
    let name = complement.dataset.name;
    let price = complement.dataset.price;
    let data = {
        productId: complement.dataset.id,
        quantity: 1,
        options: {
            width: complement.dataset.width,
            height: complement.dataset.height,
            device: complement.dataset.device,
            composition: complement.dataset.composition,
        }
    }
    let size = null;
    if (data.width !== '' && data.height !== '') {
        size = data.options.width + ' x ' + data.options.height + ' cm';
    }

    addItem(
        data,
        function(items) {
            renderCart();
            updateItemsNumber(true);
            //dataLayerAddProduct(data.reference, name, price, 1, null, size, 'normal');
            ecAddProduct(data.productId, name, price, 1, null, size, 'normal');
            ecSetAction('add');
            trackEvent('ShoppingCart', 'AddToCart', name);
            if (window.fbq && items && items[0] && items[0].reference) {
                ga4TrackSimpleEvent(
                    {
                        event: 'checkout',
                        step: 1,
                        method: 'add_complement',
                        product_id: items[0].reference
                    }
                );
                if (window.fbq) {
                    fbq('track', 'AddToCart', {
                        content_type: 'product',
                        content_name: items[0].productName || '',
                        content_ids: [items[0].reference],
                        contents: [{id: items[0].reference, quantity: 1}],
                        value: items[0].value || 0,
                        currency: items[0].currency || ''
                    });
                }
            }
        },
        function() {
            hideLoadingButton(element);
        }
    );
});

clickEvent('#cart .cart-item-modify', true, function(element) {
    let item = element.closest('.cart-item');
    trackEvent('ModifyProduct', 'Open');
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'modify_product',
            product_id: item.dataset.ecref || ''
        }
    );
    loadModifyItem(item.dataset.id);
});

clickEvent('#cart .cart-item-remove', true, function(element) {
    let item = element.closest('.cart-item');
    let quantity = item.querySelector('.quantity');
    removeItem(item.dataset.id, function() {
        renderCart();
        updateItemsNumber(true);
        ecSetAction('remove');
        dataLayerAddProduct({
            reference: item.dataset.reference,
            name: item.dataset.intname || '',
            price: item.dataset.price,
            quantity: quantity.value || 1
        }, true);
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'remove_product',
                product_id: item.dataset.reference
            }
        );
        trackEvent('ShoppingCart', 'RemoveItem', item.dataset.name);
    });
});

changeEvent('#cart #priority-service', true, function(element) {
    let id = element.value;
    let width = element.dataset.width;
    let height = element.dataset.height;
    if (element.checked) {
        let options = [];
        options['width'] = width;
        options['height'] = height;
        let data = {
            productId: id,
            quantity: 1,
            isLocked: true,
            options: options,
            extraProducts: []
        };
        addItem(
            data,
            function(items) {
                trackEvent('Cart', 'Priority', 'Add');
                ga4TrackSimpleEvent(
                    {
                        event: 'checkout',
                        step: 1,
                        method: 'add_priority'
                    }
                );
                renderCart();
                if (window.fbq && items && items[0] && items[0].reference) {
                    var payload = {
                        content_type: 'product',
                        content_name: items[0].productName || '',
                        content_ids: [items[0].reference],
                        contents: [{id: items[0].reference, quantity: 1}],
                        value: items[0].value || 0,
                        currency: items[0].currency || ''
                    };
                    fbq('track', 'AddToCart', payload);
                    sendFacebookConversionsEvent('AddToCart', payload);
                }
            }
        );
    } else {
        let cartItem = element.dataset.cartItem;
        removeItem(
            cartItem,
            function() {
                trackEvent('Cart', 'Priority', 'Remove');
                ga4TrackSimpleEvent(
                    {
                        event: 'checkout',
                        step: 1,
                        method: 'remove_priority'
                    }
                );
                renderCart();
            }
        );
    }
});

customEvent('hide.bs.offcanvas', '#cart', false, function() {
    trackEvent('ShoppingCart', 'Open/Close', '');
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'close_cart'
        }
    );
});
customEvent('show.bs.offcanvas', '#cart', false, function() {
    trackEvent('ShoppingCart', 'Open/Close', '');
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'show_cart'
        }
    );
});

clickEvent('#cart .share-cart', true, function() {
    showShareCartLink();
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'share_cart'
        }
    );
});

clickEvent('#share-cart-btn', true, function(element) {
    let input = document.querySelector('#share-cart-link');
    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value).then(() => {
        element.classList.remove('btn-dark');
        element.classList.add('btn-success');
        element.querySelector('.fas').classList.remove('fa-copy');
        element.querySelector('.fas').classList.add('fa-check');
    });
});

clickEvent('.begin_checkout', true, function(element) {
    if(!localStorage.getItem('_tv_cookie_choice')){
        setCookieForADay('_tv_in_checkout', 'y');
    }
    trackEvent('Paypal', 'GoToCheckout', 'FromShoppingCart');
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            method: 'start_order'
        }
    );
});
clickEvent('#paypal-button', true, function(element) {
    if(!localStorage.getItem('_tv_cookie_choice')){
        setCookieForADay('_tv_in_checkout', 'y');
    }
    trackEvent('Paypal', 'GoToCheckout', 'FromShoppingCart');
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'paypal'
        }
    );
});

clickEvent('#amazon-pay-button', true, function(element) {
    if(!localStorage.getItem('_tv_cookie_choice')){
        setCookieForADay('_tv_in_checkout', 'y');
    }
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'amazon_pay'
        }
    );
});

function updateQuantity(item, quantity)
{
    let itemId = item.dataset.id;
    renderLoader();
    updateItemQuantity(itemId, quantity, function(oldQuantity) {
        renderCart();
        updateItemsNumber(true);
        if (oldQuantity && quantity > oldQuantity) {
            ecSetAction('add');
            let differenceQuantity = quantity - oldQuantity;
            dataLayerAddProduct({
                reference: item.dataset.reference,
                name: item.dataset.intname || '',
                price: item.dataset.price,
                quantity: differenceQuantity
            });
            ecAddProduct(
                itemId,
                item.dataset.name,
                item.dataset.price,
                differenceQuantity,
                item.dataset.colorApa,
                item.dataset.size,
                item.dataset.mirrored
            );
        } else if (oldQuantity){
            ecSetAction('remove');
            let differenceQuantity = quantity - oldQuantity;
            dataLayerAddProduct({
                reference: item.dataset.reference,
                name: item.dataset.intname || '',
                price: item.dataset.price,
                quantity: differenceQuantity
            }, true);
        }
        trackEvent('ShoppingCart', 'ModifyQuantity', quantity);
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'modify_product_quantity'
            }
        );
    });
}