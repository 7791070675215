import {
    acceptAllCookies,
    getCookie,
    setCookie, showModalCookies
} from "../../webpack/modules/cookies";
import {getCountryCodeByIp, showCountrySelectPopUp} from "../../webpack/modules/country";
import {clickEvent} from "../../webpack/modules/events";
import {trackLinkEvent} from "../../webpack/modules/analytics";
import {getModal} from "../../webpack/modules/modal";

let userCountry = sessionStorage.getItem('_tv_user_country');
let i18nIdentified = getCookie('_tv_i18n_identified');
let cookieChoice = getCookie('_tv_cookie_choice');

if (!userCountry && !i18nIdentified) {
    getCountryCodeByIp(function(countryCode, cookieCountry) {
        sessionStorage.setItem('_tv_user_cookie_country', cookieCountry);
        if (countryCode) sessionStorage.setItem('_tv_user_country', countryCode);
        else if (cookieChoice) setCookie('_tv_i18n_identified', 'y', 30);

        if (cookieCountry) {
            if (!cookieChoice) showModalCookies();
            else showCountrySelectPopUp(countryCode)
        } else {
            showCountrySelectPopUp(countryCode);
            acceptAllCookies(365);
        }
    });
} else if (!i18nIdentified && cookieChoice) {
    if (userCountry) {
        showCountrySelectPopUp(userCountry);
    } else {
        if (cookieChoice) setCookie('_tv_i18n_identified', 'y', 30);
        else {
            let cookieCountry = sessionStorage.getItem('_tv_user_cookie_country');
            if (cookieCountry) {
                showModalCookies();
            } else {
                acceptAllCookies(365);
            }
        }
    }
} else {
    let cookieCountry = sessionStorage.getItem('_tv_user_cookie_country');
    if (cookieCountry) {
        showModalCookies();
    } else {
        acceptAllCookies(365);
    }
}

clickEvent('#modal-pop-up-country-select .option-go', true, function(element, e) {
    if (e.which === 3) return;
    let link = element.href;
    trackLinkEvent('i18nIdentifier', 'MoveTo', link.attr('title'), link);
});

clickEvent('#modal-pop-up-country-select .option-continue, #modal-pop-up-country-select .close', true, function() {
    let modal = getModal('modal-pop-up-country-select');
    setCookie('_tv_i18n_identified', 'y', 60);
    modal.hide();
    showModalCookies();
});