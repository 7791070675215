import {
    changeEvent,
    clickEvent,
    fireChangeEvent,
    keyupEvent,
    submitEvent
} from "../../../webpack/modules/events";
import {
    calculatePrice,
    checkSizeLimits,
    getProportionalHeight,
    loadOptionInfo,
    loadPreviewImage,
    previewOption,
    roundToMultiple,
    showGenderModels,
    showMaterialColor,
    updateMechanismFeatures,
    showModelSizes,
    updateMaterialColors,
    updatePackPrices
} from "../../../webpack/modules/old/product";
import {ga4TrackSimpleEvent, trackEvent} from "../../../webpack/modules/analytics";
import {updateButtons} from "../../../scripts/Tools/quantity-selector"
import {getSvgPersonalizedText} from "../../../webpack/modules/product";
import {fixSVG} from "../../../webpack/modules/svg";

submitEvent('.product-options', true, function(form, event) {
    event.preventDefault();
});

changeEvent('input[name="quantity"]', true, function(element) {
    document.querySelectorAll('.product-scope .add-to-cart-block .quantity').forEach(function (item) {
        item.value = element.value;
        let parent = item.closest('.quantity-selector');
        if (parent) {
            updateButtons(parent);
        }
    });
    if (element.dataset.calculatePrice !== 'false') calculatePrice(element.closest('.product-scope'));
});

changeEvent('.product-option input', true, function(element) {
    if (element.matches('input[name="quantity"]')) {
        return;
    }
    let scope = element.closest('.product-scope');
    let scopeName = scope.dataset.scope;
    let productIndex = element.closest('.product-options').dataset.index;
    let productOption = element.closest('.product-option');
    if (!productOption) return;
    let type = productOption.dataset.type;
    let productType = scope.dataset.productType;

    switch (type) {
        case 'size':
            if (!isNaN(element.value) && element.hasAttribute('data-multiple')) {
                roundToMultiple(element);
            }
            checkSizeLimits(productOption);
            if (element.dataset.composition) {
                loadPreviewImage(scope, productIndex, true);
            }
            let selectedSize = productOption.querySelector('input[name="size"]:checked');
            let presetsWidth = productOption.querySelector('input[name="presetsWidth"]:checked');
            let presetsHeight = productOption.querySelector('input[name="presetsHeight"]:checked');
            if (selectedSize && selectedSize.value !== 'presets-size') {
                if (presetsWidth) {
                    presetsWidth.checked = false;
                }
                if (presetsHeight) {
                    presetsHeight.checked = false;
                }
            }
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Size');
                trackProductPageGa4Events('Size', element.dataset.previewText);
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Size');
                trackEvent('ProductList', productType, 'Size');
                trackProductPageGa4Events('Size', element.dataset.previewText);
            } else if (scopeName == 'product-page') {
                trackEvent('ProductPage', 'Size');
                trackProductPageGa4Events('Size', element.dataset.previewText);
            }
            break;
        case 'pack':
            //nobreak
        case 'customPack':
            updatePackPrices(scope, productIndex);
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Pack');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Pack');
                trackEvent('ProductList', productType, 'Pack');
            }
            break;
        case 'effect':
            let photomontage = false;
            if (scope.querySelector('input[name="size"][data-composition]:not([data-composition=""])')) {
                photomontage = true;
            }
            loadPreviewImage(scope, productIndex, photomontage);
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Effects');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Effects');
                trackEvent('ProductList', productType, 'Effects');
            }
            break;
        case 'gender':
            showGenderModels(scope, productIndex);
            break;
        case 'model':
            showModelSizes(scope, productIndex);
            updateMaterialColors(scope, productIndex);
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Model');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Model');
                trackEvent('ProductList', productType, 'Model');
            }
            break;
        case 'materialColor':
            showMaterialColor(scope, productIndex);
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'TshirtColor');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'TshirtColor');
                trackEvent('ProductList', productType, 'TshirtColor');
            }
            break;
        case 'color':
            loadPreviewImage(scope, productIndex, false);
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Color');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Color');
                trackEvent('ProductList', productType, 'Color');
            }
            break;
        case 'orientation':
            loadPreviewImage(scope, productIndex, false);
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Orientation');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Orientation');
                trackEvent('ProductList', productType, 'Orientation');
            }
            break;
        case 'glass-reversed':
            let eventCategory = null;
            if (scopeName == 'product-page') {
                eventCategory = 'ProductPage';
            } else if (scopeName == 'shopping-cart') {
                eventCategory = 'ModifyProduct';
            } else if (scopeName == 'product-popup') {
                eventCategory = 'ProductList';
            }
            if (eventCategory) {
                if (element.checked) {
                    trackEvent(eventCategory, 'GlassReversed', 'add');
                    if (eventCategory === 'ProductPage') {
                        trackProductPageGa4Events('GlassReversed', 'add');
                    }
                } else {
                    trackEvent(eventCategory, 'GlassReversed', 'remove');
                    if (eventCategory === 'ProductPage') {
                        trackProductPageGa4Events('GlassReversed', 'remove');
                    }
                }
            }
            break;
        case 'mechanism':
            updateMechanismFeatures(scope, productIndex);
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Mechanism');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Mechanism');
                trackEvent('ProductList', productType, 'Mechanism');
            }
            break;
        case 'customizer':
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Customizer');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Customizer');
            }

            if (element.classList.contains('custom-file-input') && scopeName === 'product-popup') {
                trackEvent('ProductList', productType, 'CustomImage');
            } else if (element.name === 'customText' && scopeName === 'product-popup') {
                trackEvent('ProductList', productType, 'CustomText');
            }
            break;
        case 'tissue':
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Fabric');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Fabric');
                trackEvent('ProductList', productType, 'Fabric');
            }
            break;
        case 'drop':
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Drop');
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Drop');
                trackEvent('ProductList', productType, 'Drop');
            }
            break;
        case 'modelSize':
            if (scopeName == 'shopping-cart') {
                trackEvent('ModifyProduct', 'Size');
                trackProductPageGa4Events('Size', element.dataset.previewText);
            } else if (scopeName == 'product-popup') {
                trackEvent('ProductList', 'Size');
                trackEvent('ProductList', productType, 'Size');
                trackProductPageGa4Events('Size', element.dataset.previewText);
            } else if (scopeName == 'product-page') {
                trackEvent('ProductPage', 'Size');
                trackProductPageGa4Events('Size', element.dataset.previewText);
            }
            break;
    }

    if (productOption.dataset.updatePrice == 'true') {
        calculatePrice(scope);
    }

    previewOption(productOption);
});

clickEvent('.on-click-check', true, function(element) {
    let input = document.querySelector(element.dataset.check);
    input.checked = true;
    fireChangeEvent(input, true);
});

changeEvent('.custom-width', true, function(element) {
    getProportionalHeight(element);
});

changeEvent('input[name="customImage"]', true, function(element) {
    if (element.value) {
        let productOption = element.closest('.product-option');
        if (!productOption) return;
        if (productOption.querySelector('.custom-image-from-web'))
            productOption.querySelector('.custom-image-from-web').checked = false;
        if (productOption.querySelector('input[name="customPriceIncrement"]')) {
            productOption.querySelector('input[name="customPriceIncrement"]').checked = true;
            let scope = element.closest('.product-scope');
            calculatePrice(scope);
        }
    }
});

changeEvent('.custom-image-from-web', true, function(element) {
    let productOption = element.closest('.product-option');
    if (!productOption) return;
    if (element.checked) {
        productOption.querySelector('input[name="file-customImage"]').value = null;
        productOption.querySelector('input[name="display-customImage"]').value = '';
        productOption.querySelector('input[name="customImage"]').value = '';
        if (productOption.querySelector('input[name="customPriceIncrement"]')) {
            if (productOption.querySelector('input[name="customText"]')) {
                if (!productOption.querySelector('input[name="customText"]')) {
                    productOption.querySelector('input[name="customPriceIncrement"]').checked = false;
                }
            } else {
                productOption.querySelector('input[name="customPriceIncrement"]').checked = false;
            }
            let scope = element.closest('.product-scope');
            calculatePrice(scope);
        }
    } else {
        if (productOption.querySelector('input[name="customPriceIncrement"]')) {
            productOption.querySelector('input[name="customPriceIncrement"]').checked = true;
            let scope = element.closest('.product-scope');
            calculatePrice(scope);
        }
    }
});

changeEvent('.custom-price-increment', true, function(element) {
    let productOption = element.closest('.product-option');
    if (!productOption) return;
    if (!element.checked) {
        if (productOption.querySelector('input[name="customText"],textarea[name="customText"]')) {
            productOption.querySelector('input[name="customText"],textarea[name="customText"]').value = null;
        }
        if (productOption.querySelector('.custom-image-from-web')) {
            productOption.querySelector('.custom-image-from-web').checked = true;
        }
        if (productOption.querySelector('input[name="customImage"]')) {
            productOption.querySelector('input[name="file-customImage"]').value = null;
            productOption.querySelector('input[name="display-customImage"]').value = '';
            productOption.querySelector('input[name="customImage"]').value = '';
        }
    } else {
        if (productOption.querySelector('.custom-image-from-web')) {
            productOption.querySelector('.custom-image-from-web').checked = false;
        }
    }
    let scope = element.closest('.product-scope');
    calculatePrice(scope);
    previewOption(productOption);
});


let textOldValue;
keyupEvent('input[name="customText"],textarea[name="customText"]', true, function(element) {
    let productOption = element.closest('.product-option');
    if (!productOption) return;
    let productScope = element.closest('.product-scope');
    if (productOption.querySelector('input[name="customPriceIncrement"]')) {
        productOption.querySelector('input[name="customPriceIncrement"]').checked = true;
        calculatePrice(productScope);
    }
});

changeEvent('input[name="customText"],textarea[name="customText"]', true, function(element) {
    let productOption = element.closest('.product-option');
    if (!productOption) return;
    let productScope = element.closest('.product-scope');
    let customWidth = productScope.querySelector('input[name="customWidth"]');
    let personalizedImagePreview = productScope.querySelector('input[name="personalizedImagePreview"]');
    let personalizedSvg = productScope.querySelector('input[name="personalizedSvg"]');
    if (element.value && element.value != textOldValue && personalizedImagePreview) {
        showLoader(productScope);
        getSvgPersonalizedText(productScope, function(svg, svgPreview) {
            hideLoader(productScope);
            personalizedSvg.value = fixPersonalizedSVG(svg, productScope);
            if (svgPreview) personalizedImagePreview.value = svgPreview;
            if (customWidth && customWidth.value) fireChangeEvent(customWidth, true);
            if (loader) loader.classList.add('d-none');
        });

        textOldValue = element.value;
    }
});
function fixPersonalizedSVG(personalizedSVG, scope)
{
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = personalizedSVG;
    let svg = tempDiv.querySelector('svg');
    fixSVG(svg);
    let viewBox = svg.getAttribute('viewBox').split(' ');
    let factor = scope.querySelector('input[name="factor"]');
    if (factor) factor.value = viewBox[3] / viewBox[2];

    return tempDiv.innerHTML;
}


clickEvent('.option-info', true, function(element, event) {
    event.stopPropagation();
    let scope = element.closest('.product-scope');
    let model = null;
    let selectedModel = scope.querySelector('input[name=model]:checked');
    if (selectedModel) {
        model = selectedModel.value;
    }
    let type = element.dataset.info;
    let productId = element.dataset.productId;
    loadOptionInfo(type, productId, model);
});

changeEvent('.product-complement, .cross-selling-product', true, function(element) {
    calculatePrice(element.closest('.product-scope'));
    let analyticsCategory = element.dataset.analyticsCategory;
    let analyticsAction = element.dataset.analyticsAction;
    let analyticsLabel = element.dataset.analyticsLabel;
    if (element.checked) {
        trackEvent(analyticsCategory, analyticsAction, analyticsLabel);
        trackProductPageGa4Events(analyticsAction, analyticsLabel);
    }
});

function trackProductPageGa4Events(event, extraData)
{
    let richCard = document.querySelector('script[type="application/ld+json"]');
    let sku = '';
    let name = '';
    if (richCard) {
        let jsonld = JSON.parse(richCard.innerText);
        sku = jsonld ? jsonld.sku || '' : '';
        name = jsonld ? jsonld.name || '' : '';
    } else {
        let popup = document.getElementById('product-popup');
        sku = popup ? popup.dataset.productReference : '';
        name = popup ? popup.dataset.productName : '';
    }
    switch (event) {
        case 'SampleImage':
            ga4TrackSimpleEvent({
                "event": 'sample_image',
                "product_id": sku,
                "product_name": name
            });
            break;
        case 'Complement':
            ga4TrackSimpleEvent({
                "event": 'select_complement',
                "complement": extraData,
                "product_id": sku,
                "product_name": name
            });
            break;
        case 'GlassReversed':
            if (extraData === 'add') {
                ga4TrackSimpleEvent({
                    "event": 'select_glass_reversed',
                    "product_id": sku,
                    "product_name": name
                });
            } else {
                ga4TrackSimpleEvent({
                    "event": 'unselect_glass_reversed',
                    "product_id": sku,
                    "product_name": name
                });
            }
            break;
        case 'Size':
            ga4TrackSimpleEvent({
                "event": 'select_size',
                "size": extraData,
                "product_id": sku,
                "product_name": name
            });
            break;
    }
}


function showLoader(scope)
{
    scope.querySelectorAll('.btn-add-to-cart').forEach(function(element) {
        element.disabled = true;
        let loader = element.querySelector('.loader');
        let elementToHide = element.querySelector('.text');
        elementToHide.classList.add('d-none');
        loader.classList.remove('d-none');
    });
}

function hideLoader(scope)
{
    scope.querySelectorAll('.btn-add-to-cart').forEach(function(element) {
        element.disabled = false;
        let loader = element.querySelector('.loader');
        let elementToHide = element.querySelector('.text');
        loader.classList.add('d-none');
        elementToHide.classList.remove('d-none');
    });
}