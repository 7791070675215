import {clickEvent} from "../../webpack/modules/events";
import {slideUp} from "../../webpack/modules/element-visibility";
import {ga4TrackSimpleEvent} from "../../webpack/modules/analytics";

clickEvent('.header-messages .close', false, function(element) {
    if (!localStorage) return;
    let date = new Date();
    let div = element.closest('.header-messages');
    localStorage.setItem('tvn_header_messages_close', date.getTime());
    slideUp(div);
    ga4TrackSimpleEvent(
        {
            event: 'loyalty',
            method: 'close'
        }
    );
});