import {post} from "./ajax";

export function getCountryDropdown(callback)
{
    post(
        '/ajax/website/left-menu/get-country-dropdown',
        {},
        function(response) {
            callback(response.view);
        }
    );
}