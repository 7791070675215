import {changeEvent, clickEvent, fireChangeEvent, keyupEvent} from "../../webpack/modules/events";

clickEvent('.quantity-selector .change-quantity', true, function(element) {
    let action = element.dataset.action;
    let quantitySelector = element.closest('.quantity-selector');
    let quantityInput = quantitySelector.querySelector('input[type="number"]');
    let quantity = quantityInput.value;

    if (quantitySelector.classList.contains('disabled')) return;

    if (action === 'add') {
        quantity++;
    } else if (action === 'remove') {
        quantity--;
    }
    quantityInput.value = quantity;
    fireChangeEvent(quantityInput, true);
    updateButtons(quantitySelector);
});

keyupEvent('.quantity', true, function(quantity) {
    fireChangeEvent(quantity, true);
});

changeEvent('.quantity', true, function(element, event) {
    let value = element.value;
    let quantitySelector = element.closest('.quantity-selector');
    if (!quantitySelector || quantitySelector.tagName === 'SELECT') return;

    if (element.hasAttribute('min')) {
        let min = element.getAttribute('min');
        if (min && value < min) {
            event.stopPropagation();
            element.value = min;
            fireChangeEvent(element, true);
            return;
        }
    }
    if (element.hasAttribute('max')) {
        let max = element.getAttribute('max');
        if (max && value > max) {
            event.stopPropagation();
            element.value = max;
            fireChangeEvent(element, true);
            return;
        }
    }

    uploadQuantity(element.value);
    updateButtons(quantitySelector);
});

export function updateButtons(quantitySelector)
{
    let quantityInput = quantitySelector.querySelector('input[type="number"]');
    let removeButton = quantitySelector.querySelector('.change-quantity[data-action="remove"]');
    let addButton = quantitySelector.querySelector('.change-quantity[data-action="add"]');
    let quantity = quantityInput.value;

    if (quantityInput.hasAttribute('min')) {
        let min = quantityInput.getAttribute('min');
        if (quantity <= min) {
            removeButton.setAttribute('disabled', true);
        } else {
            removeButton.removeAttribute('disabled');
        }
    }
    if (quantityInput.hasAttribute('max')) {
        let max = quantityInput.getAttribute('max');
        if (quantity >= max) {
            addButton.setAttribute('disabled', true);
        } else {
            addButton.removeAttribute('disabled');
        }
    }
}

function uploadQuantity(quantity)
{
    document.querySelectorAll('.quantity-add-to-cart').forEach(function(element) {
        if (element.classList.contains('update-quantity-info')) {
            let quantityInfo = element.parentElement.querySelector('.quantity-info');
            if (quantity == 1) quantityInfo.innerHTML = quantityInfo.dataset.singular;
            else quantityInfo.innerHTML = quantityInfo.dataset.plural;
        }
        element.value = quantity;
    });
}