import {slideDown, slideUp} from "./element-visibility";
import {sendForm} from "./form";
import {isMobile} from "./devices";

export function sendLogin(form)
{
    let errorResponse = form.querySelector('.error-response');
    slideUp(errorResponse);
    sendForm(
        form,
        function(data) {
            let access = form.closest('.access-content');
            dataLayer.push(
                {
                    "event": "login",
                    "method": "form"
                }
            );
            window.location = access.querySelector('input[name="redirectUrl"]').value;
        },
        function(data) {
            errorResponse.innerHTML = data.message;
            slideDown(errorResponse);
            if (isMobile()) window.scrollTo({top: 0, behavior: 'smooth'});
        }
    );
}

export function sendRecoverPassword(form)
{
    let successResponse = form.querySelector('.success-response');
    let errorResponse = form.querySelector('.error-response');
    let access = form.closest('.access-content');
    slideUp(successResponse);
    slideUp(errorResponse);
    sendForm(
        form,
        function(data) {
            successResponse.innerHTML = data.message;
            slideDown(successResponse);
            setTimeout(function() {
                slideUp(successResponse);
                closeRecoverPasswordForm(access);
            }, 4000);
        },
        function(data) {
            errorResponse.innerHTML = data.message;
            slideDown(errorResponse);
            if (isMobile()) {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        }
    );
}

export function sendRegister(form)
{
    let errorResponse = form.querySelector('.error-response');
    slideUp(errorResponse);
    sendForm(
        form,
        function(data) {
            dataLayer.push(
                {
                    "event": "sign_up",
                    "method": "form"
                }
            );
            location.reload();
        },
        function(data) {
            errorResponse.innerHTML = data.message;
            slideDown(errorResponse);
            if (isMobile()) window.scrollTo({top: 0, behavior: 'smooth'});
        }
    );
}

export function openRecoverPasswordForm(access)
{
    slideUp(access.querySelector('.login-form-container'), 200, function() {
        if (isMobile()) window.scrollTo({top: 0, behavior: 'smooth'});
        slideDown(access.querySelector('.recover-password-form-container'), 200, function() {
            access.dataset.current = 'recover-password';
        });
    });
}

export function closeRecoverPasswordForm(access)
{
    slideUp(access.querySelector('.recover-password-form-container'), 200, function() {
        if (isMobile()) window.scrollTo({top: 0, behavior: 'smooth'});
        slideDown(access.querySelector('.login-form-container'), 200, function() {
            access.dataset.current = 'login';
        });
    });
}

export function openRegisterForm(access)
{
    slideUp(access.querySelector('.login-form-container'), 200, function() {
        if (isMobile()) window.scrollTo({top: 0, behavior: 'smooth'});
        slideDown(access.querySelector('.register-form-container'), 200, function() {
            access.dataset.current = 'register';
            google.accounts.id.renderButton(
                access.querySelector('.register-form-container').querySelector('.g_id_signin'),
                { type: "icon", size: "large", theme: "outline" }  // customization attributes
            );
        });
    });
}

export function closeRegisterForm(access)
{
    slideUp(access.querySelector('.register-form-container'), 200, function() {
        if (isMobile()) window.scrollTo({top: 0, behavior: 'smooth'});
        slideDown(access.querySelector('.login-form-container'), 200, function() {
            access.dataset.current = 'login';
            google.accounts.id.renderButton(
                access.querySelector('.login-form-container').querySelector('.g_id_signin'),
                { type: "icon", size: "large", theme: "outline" }  // customization attributes
            );
        });
    });
}