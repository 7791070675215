import {get} from "./ajax";
import {openModalFromHtml} from "./modal";
import {previewScopeOptions} from "./old/product";
import {isDesktop, isMobile, isTablet} from "./devices";

let loadingPopup = false;
export function loadProductPopup(id)
{
    if (id && !loadingPopup) {
        loadingPopup = true;
        showButtonLoading(id);
        get(
            '/ajax/product/load-popup/'+id,
            {},
            function(data) {
                openModalFromHtml(data.view);
                previewScopeOptions(document.querySelector('#product-popup'));
                loadingPopup = false;
                hideButtonLoading(id);
            },
            function() {
                loadingPopup = false;
                hideButtonLoading(id);
            }
        );
    }
}

function showButtonLoading(id)
{
    let button = document.querySelector('.list-add-to-cart[data-id="'+id+'"]');
    button.disabled = true;
    button.querySelector('.loader').classList.remove('d-none');
    button.querySelector('.text, .cart-icon').classList.add('d-none');
}

function hideButtonLoading(id)
{
    let button = document.querySelector('.list-add-to-cart[data-id="'+id+'"]');
    button.disabled = false;
    button.querySelector('.loader').classList.add('d-none');
    button.querySelector('.text, .cart-icon').classList.remove('d-none');
}

export function getFilters()
{
    let filters = {};
    let checkboxes = [];
    if (isDesktop()) checkboxes = document.querySelectorAll('#filters-desktop .filter input[type="checkbox"]:checked');
    if (isMobile() || isTablet()) checkboxes = document.querySelectorAll('#filters-mobile .filter input[type="checkbox"]:checked');
    checkboxes.forEach(function(checkbox) {
        let value = checkbox.value;
        let type = checkbox.dataset.type;
        if (!filters.hasOwnProperty(type)) filters[type] = [];
        filters[type].push(value);
    });

    return filters;
}