import Splide from "@splidejs/splide";

export function initImageClaimsSlider()
{
    let elements = document.querySelectorAll('.image-claims-slider');
    elements.forEach(function(element) {
        let splide = new Splide(element, {
            type: 'fade',
            rewind: true,
            arrows: false,
            pagination: false,
            drag: false,
            autoplay: true,
            speed: 1000
        });

        splide.mount();
    });
}