import {clickEvent, customEvent, documentLoadEvent} from "../../webpack/modules/events";
import {ga4TrackSimpleEvent, itemDatalayerPush} from "../../webpack/modules/analytics";
import {navigateHeaderMenu, replaceCountryLinks, toggleCountrySelector} from "../../webpack/modules/left-menu";
import {onVisible} from "../Tools/visible-element-observer";

documentLoadEvent(function() {
    replaceCountryLinks();
});

onVisible(document.querySelectorAll("#country-selector img"), (image) => image.src = image.dataset.src);

clickEvent('#country-selector-btn', false, toggleCountrySelector);

customEvent('hidden.bs.offcanvas', '#left-menu', false, function() {
    let mainSectionId = document.querySelector('.hm-section[data-type="main"]').dataset.id;
    navigateHeaderMenu(mainSectionId, false);
    ga4TrackSimpleEvent(
        {
            event: 'header_menu',
            method: 'close'
        }
    );
});

clickEvent('.hm-navigate', false, function(button) {
    let parent = button.parent;
    //let level = parent && parent.classList.contains('i-0') ?
    navigateHeaderMenu(button.dataset.id, true);
    ga4TrackSimpleEvent(
        {
            event: 'header_menu',
            method: 'navigate',
            link_id: button.dataset.linkId ? button.dataset.linkId : null,
            link_text: button.dataset.linkText ? button.dataset.linkText : null
        }
    );
});

clickEvent('.hm-back', false, function(button) {
    navigateHeaderMenu(button.dataset.id, false);
    ga4TrackSimpleEvent(
        {
            event: 'header_menu',
            method: 'back'
        }
    );
});