import Splide from "@splidejs/splide";

export function initDesktopSlider()
{
    let slider = document.querySelector('#header-bottom .categories-desktop .splide');
    if (!slider) return
    let splide = new Splide(slider, {
        pagination: false,
        autoWidth: true,
        breakpoints: {
            640: {
                focus: 'center',
            },
        }
    }).mount();

    let visibleSlides = document.querySelectorAll('#header-bottom .categories-desktop .splide__slide.is-visible').length;
    let allSlides = splide.Components.Elements.slides.length;
    splide.options = {perPage: visibleSlides};
    if (visibleSlides === allSlides) {
        splide.options = {drag: false};
        document.querySelector('#header-bottom .categories-desktop .splide__list').style.justifyContent = "left";
    }
}

export function initMobileSlider()
{
    let slider = document.querySelector('#header-bottom .categories-mobile .splide');
    if (!slider) return
    new Splide(slider, {
        pagination: false,
        autoWidth: true,
        breakpoints: {
            640: {
                focus: 'center',
            },
        }
    }).mount();
}