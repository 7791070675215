import {clickEvent} from "../../webpack/modules/events";
import {hideModal} from "../../webpack/modules/modal";
import {addToCart, modifyCartItem} from "../../webpack/modules/old/product";
import {openCart, renderCart} from "../../webpack/modules/cart";
import {ga4TrackSimpleEvent, trackEvent} from "../../webpack/modules/analytics";
import {loadProductPopup} from "../../webpack/modules/product-list";

const listType = document.querySelector('.product-list.search-results') ? 'search_results' : 'product_list';

clickEvent('#close-modal-product', true, function(element) {
    let scopeName = element.closest('#product-popup').dataset.scope;
    if (scopeName == 'shopping-cart') {
        trackEvent('ModifyProduct', 'Close');
    } else if (scopeName == 'product-popup') {
        let productType = element.dataset.productType;
        trackEvent('ProductList', 'Close');
        trackEvent('ProductList', productType, 'close');
    }
    hideModal('modal-product');
});

clickEvent('#product-popup[data-scope="shopping-cart"] .btn-add-to-cart', true, function(element) {
    let scope = element.closest('.product-scope');
    modifyCartItem(
        scope,
        function() {
            hideModal('modal-product');
            renderCart();
        }
    );
});

clickEvent('#product-popup[data-scope="product-popup"] .btn-add-to-cart', true, function(element) {
    let scope = element.closest('.product-scope');
    addToCart(
        scope,
        function() {
            hideModal('modal-product');
            openCart();
        }
    );
});

clickEvent('.list-add-to-cart', true, function(element, event) {
    event.preventDefault();
    event.stopPropagation();
    let id = element.dataset.id;
    let productType = element.dataset.productType;
    trackEvent('ProductList', 'AddToCartPopUp', 'open');
    trackEvent('ProductList', productType, 'open');
    ga4TrackSimpleEvent({
        'event': listType,
        'method': 'add_to_cart_popup',
        'content_type': 'open'
    });
    loadProductPopup(id);
});
