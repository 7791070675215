import {clickEvent, windowScrollEvent} from "../../webpack/modules/events";
import {slideDown, slideUp, slideUpBySelector} from "../../webpack/modules/element-visibility";
import {post} from "../../webpack/modules/ajax";
import {ga4TrackSimpleEvent, trackEvent} from "../../webpack/modules/analytics";
import {hideCart, openCart, updateItemsNumber} from "../../webpack/modules/cart";
import {isDesktop, isMobile} from "../../webpack/modules/devices";
import {renderDropdown} from "../../webpack/modules/header";
import {showRecoveryPasswordForm} from "../../webpack/modules/login";
import {initHeaderMessagesSlider} from "../../webpack/modules/header-messages-slider";
import {initImageClaimsSlider} from "../../webpack/modules/image-claims-slider";
import {isLogged} from "../../webpack/modules/user";
import {initHeaderOuterMenuSlider} from "../../webpack/modules/header-outer-menu-slider";

document.addEventListener("DOMContentLoaded", function() {
    initHeaderMessagesSlider();
    initImageClaimsSlider();
    initHeaderOuterMenuSlider();
    updateItemsNumber();
});

let oldScrollPosition = window.scrollY;
windowScrollEvent(function(event) {
    let oldHeader = document.querySelector('#header');
    let newHeader = document.querySelector('header');
    if (isDesktop() && oldHeader) {
        if (window.scrollY > 100) {
            oldHeader.classList.add('header-min');
        } else {
            oldHeader.classList.remove('header-min');
        }
    } else if (isMobile() && newHeader) {
        let header = document.querySelector('header');
        let headerMessages = document.querySelector('.header-messages');
        if (headerMessages) {
            let navigationBar = document.querySelector('header .mobile .navbar-content');
            let limit = headerMessages.offsetHeight + navigationBar.offsetHeight;
            if (window.scrollY > limit && !header.classList.contains('header-min') && window.scrollY > oldScrollPosition) {
                header.classList.add('header-min');
            } else if (window.scrollY < oldScrollPosition) {
                header.classList.remove('header-min');
            }
            oldScrollPosition = window.scrollY;
        }
    }
});

clickEvent('#goToSearch', false, function() {
    window.scrollTo(0, 0);
});

clickEvent('.header-dropdown', false, function(element, event) {
    //TODO IMPLEMENT MODULES DYNAMICALLY
    let target = event.target;
    if (target.classList.contains('content') || target.closest(".content")) {
        return;
    }
    let type = element.dataset.type;
    let dropdown = element.parentElement.querySelector('.content');
    let hasContent = dropdown.childNodes.length;
    let isShowed = (dropdown.offsetWidth && dropdown.offsetHeight);
    let category = element.dataset.analyticsCategory;
    let action = element.dataset.analyticsAction;
    let label = element.dataset.analyticsLabel;
    let eventName = element.dataset.gaEvent || null;
    if (!hasContent) {
        renderDropdown(type, function(content) {
            dropdown.innerHTML = content;
            if (type === 'countries' || type === 'languages') replaceCountryLinks();
            if (type === 'user') {
                window.facebookSignWithInit();
                google.accounts.id.renderButton(
                    dropdown.querySelector('.g_id_signin'),
                    { type: "icon", size: "large", theme: "outline" }  // customization attributes
                );
            }
            showDropdown(dropdown);
            if (eventName) {
                ga4TrackSimpleEvent(
                    {
                        event: eventName,
                        method: 'open'
                    }
                );
            }
        });
    } else if (isShowed) {
        hideDropdown(dropdown);
        if (eventName) {
            ga4TrackSimpleEvent(
                {
                    event: eventName,
                    method: 'close'
                }
            );
        }
    } else {
        showDropdown(dropdown);
        if (eventName) {
            ga4TrackSimpleEvent(
                {
                    event: eventName,
                    method: 'open'
                }
            );
        }
    }
    trackEvent(category, action, label);
});

window.addEventListener('click', function(e) {
    let target = event.target;
    if (!target.classList.contains('header-dropdown') && !target.closest(".header-dropdown")) {
        let headerDropdown = getHeaderDropdownVisible();
        if (headerDropdown) {
            let parent = headerDropdown.closest('.header-dropdown');
            let category = parent.dataset.analyticsCategory;
            let action = parent.dataset.analyticsAction;
            let label = parent.dataset.analyticsLabel;
            trackEvent(category, action, label);
            slideUp(headerDropdown);
        }
    }
});

clickEvent('#header .show-recovery-password-form, .hide-recovery-password-form', true, function(element) {
    let action = element.classList.contains('show-recovery-password-form') ? 'show' : 'hide';
    let loginForm = document.querySelector('#header .login-form');
    let recoveryPasswordForm = document.querySelector('#header .recovery-password-form');
    showRecoveryPasswordForm(action, loginForm, recoveryPasswordForm)
});

clickEvent('.modal .show-recovery-password-form, .hide-recovery-password-form', true, function(element) {
    let action = element.classList.contains('show-recovery-password-form') ? 'show' : 'hide';
    let loginForm = document.querySelector('.modal .login-form');
    let recoveryPasswordForm = document.querySelector('.modal .recovery-password-form');
    showRecoveryPasswordForm(action, loginForm, recoveryPasswordForm)
});

clickEvent('#header .open-cart, #cart .hide-cart, header .open-cart', true, function(element) {
    if (element.classList.contains('open-cart')) {
        openCart();
        updateItemsNumber(true);
    } else if (element.classList.contains('hide-cart')) {
        hideCart();
    }
});

clickEvent('#cart .share-cart', true, function() {
    showShareCartLink();
});

/** @deprecated */
clickEvent('#share-cart-btn', true, function(element) {
    let input = document.querySelector('#share-cart-link');
    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value).then(() => {
        element.classList.remove('btn-dark');
        element.classList.add('btn-success');
        element.querySelector('.fas').classList.remove('fa-copy');
        element.querySelector('.fas').classList.add('fa-check');
    });
});

clickEvent('.copy-from', true, function(element) {
    let copyFrom = element.dataset.copyFrom;
    if (copyFrom) {
        let input = document.getElementById(copyFrom);
        if (input) {
            input.select();
            input.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(input.value).then(() => {
                element.classList.remove('btn-dark');
                element.classList.add('btn-success');
                element.querySelector('.tvi').classList.remove('tvi-copy');
                element.querySelector('.tvi').classList.add('tvi-check-solid');
            });
        }
    }
});

clickEvent('#header .categories .link', false, function(category) {
    let productTypeName = category.closest('.categories').dataset.productTypeName;
    if (isMobile()) trackEvent('header', productTypeName, category.textContent);
});

if (isLogged()) {
    post(
        '/ajax/website/header/get-logged-user-name',
        {},
        function (response) {
            let text, icon;
            let desktopElement = document.querySelector('#header-user, header .desktop .header-dropdown[data-type="user"]');
            if (desktopElement) {
                let column = desktopElement.parentElement;
                text = desktopElement.querySelector('.user-name');
                icon = desktopElement.querySelector('.fa-user, .tvi-user');
                column.classList.remove("user-register");
                column.classList.add("user-logged");
                text.innerHTML = response.name;
                icon.classList.remove('text-gray');
                icon.classList.add('text-green');
            }

            let mobileElement = document.querySelector('#header-mobile-user, header .mobile');
            if (mobileElement) {
                text = mobileElement.querySelector('.user-name');
                icon = mobileElement.querySelector('.fa-user, .tvi-user');
                if (text) text.innerHTML = response.name;
                icon.classList.remove('text-gray');
                icon.classList.add('text-green');
            }

            let mobileMenuElement = document.querySelector('#left-menu-user');
            if (mobileMenuElement) {
                text = mobileMenuElement.querySelector('.user-name');
                icon = mobileMenuElement.querySelector('.fa-user');
                text.innerHTML = response.name;
                icon.classList.remove('text-gray');
                icon.classList.add('text-green');
            }
        }
    );
}

function getHeaderDropdownVisible()
{
    let elementVisible = null;
    let elements = document.querySelectorAll('.header-dropdown .content');
    elements.forEach(function(element) {
        if (element.offsetWidth && element.offsetHeight) {
            elementVisible = element;
        }
    });

    return elementVisible;
}

function showDropdown(dropdown)
{
    document.querySelectorAll('.header-dropdown .content').forEach(function(element) {
        let isVisible = element.offsetWidth && element.offsetHeight;
        let parent = element.closest('.header-dropdown');
        let category = parent.dataset.analyticsCategory;
        let action = parent.dataset.analyticsAction;
        let label = parent.dataset.analyticsLabel;
        if (isVisible) trackEvent(category, action, label);
    });

    slideUpBySelector('.header-dropdown .content', 200, function() {
        slideDown(dropdown);
    });
}

function hideDropdown(dropdown)
{
    slideUp(dropdown);
}

function replaceCountryLinks()
{
    let alternates = document.querySelectorAll('link[rel=alternate]');
    alternates.forEach(function(alternate) {
        let hreflang = alternate.getAttribute('hreflang');
        let locale = hreflang.replace("-","_");
        let href = alternate.getAttribute('href');
        let link = document.querySelector(`.country-link[data-locale=${locale}], .language-link[data-locale=${locale}]`);
        if (link) link.setAttribute("href", href);
    });
}