import {post} from "./ajax";

let ajaxVoucher = null;
export function applyVoucher(code, callbackSuccess, callbackError)
{
    if (ajaxVoucher) ajaxVoucher.abort();
    ajaxVoucher = post(
        '/ajax/website/voucher/apply',
        {code: code},
        function (data) {
            ajaxVoucher = null;
            if (callbackSuccess) callbackSuccess(data.voucherWarningMessage);
        },
        function(data) {
            ajaxVoucher = null;
            if (callbackError && data.voucherErrorMessage) callbackError(data.voucherErrorMessage);
        }
    );
}

export function removeVoucher(callbackSuccess, callbackError)
{
    if (ajaxVoucher) ajaxVoucher.abort();
    ajaxVoucher = post(
        '/ajax/website/voucher/remove',
        {},
        function () {
            ajaxVoucher = null;
            if (callbackSuccess) callbackSuccess();
        },
        function(data) {
            ajaxVoucher = null;
            if (callbackError && data.voucherErrorMessage) callbackError(data.voucherErrorMessage);
        }
    );
}