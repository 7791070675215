export function showLoadingButton(button)
{
    button.disabled = true;
    let loader = button.querySelector('.loader');
    let elementToHide = button.querySelector('.text');
    elementToHide.classList.add('d-none');
    loader.classList.remove('d-none');
}

export function hideLoadingButton(button)
{
    button.disabled = false;
    let loader = button.querySelector('.loader');
    let elementToHide = button.querySelector('.text');
    loader.classList.add('d-none');
    elementToHide.classList.remove('d-none');
}