let loadModules = {};

export function loadModule(moduleName, callback)
{
    if (!(moduleName in loadModules)) {
        import(`../modules/${moduleName}`).then(function (module) {
            loadModules[moduleName] = module;
            callback(module);
        });
    } else {
        callback(loadModules[moduleName]);
    }
}