import {slideDown, slideUp} from "./element-visibility";
import {sendForm} from "./form";

export function loginForm(form) {
    let errors = form.querySelector('.errors');
    sendForm(
        form,
        function() {
            dataLayer.push(
                {
                    "event": "login",
                    "method": "form"
                }
            );
            location.reload();
        },
        function(data) {
            errors.innerHTML = data.message;
            slideDown(errors);
        }
    );
}

export function registerForm(form) {
    let errors = form.querySelector('.errors');
    sendForm(
        form,
        function() {
            location.reload();
        },
        function(data) {
            errors.innerHTML = data.error;
            slideDown(errors);
        }
    );
}

export function recoveryPasswordForm(form, successMessages) {
    let errors = form.querySelector('.errors');
    slideUp(errors);
    slideUp(successMessages);
    sendForm(
        form,
        function(data) {
            successMessages.innerHTML = data.message;
            slideDown(successMessages);
            form.querySelector('.hide-recovery-password-form').click();
            form.reset();
        },
        function(data) {
            errors.innerHTML = data.message;
            slideDown(errors);
        }
    );
}

export function showRecoveryPasswordForm(action, loginForm, recoveryPasswordForm) {
    let hideElement = action === 'show' ? loginForm : recoveryPasswordForm;
    let showElement = action === 'show' ? recoveryPasswordForm : loginForm;
    slideUp(hideElement, 200, function() {
        slideDown(showElement);
    });
}