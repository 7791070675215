import {sendPopupEvent, trackEvent} from "../../../library/analytics";
import {post} from "./ajax";
import {openCart} from "./cart";
import {getCookie} from "./cookies";
import {getModal, openModalFromHtml} from "./modal";

export function approvePopUp()
{
    let popUp = document.getElementById('pop-up-smarter-click').value;
    if (popUp === 'POP UP EMAIL') {
        let privacyElement = document.getElementById('pop-up-smarter-click-privacy');
        let email = document.getElementById('pop-up-smarter-click-email');

        if (email.value === '') {
            email.classList.add('error');
            return;
        }

        if (!privacyElement.checked) {
            privacyElement.classList.add('error');
            return;
        }

        post('/ajax/pop-ups/smarter-click/send-email', { email: email.value });
        getModal('modal-pop-up-smarter-click').hide();
    } else {
        getModal('modal-pop-up-smarter-click').hide();
        let voucherCode = document.getElementById('pop-up-smarter-click-voucher-code').value;
        post(
            '/ajax/website/voucher/apply',
            {
                code: voucherCode
            },
            function() {
                openCart();
            },
        );
    }
}

export function showDesktopPopUp(event)
{
    let windowHeight = window.innerHeight;
    if (event.offsetY < 0 || event.offsetY >= windowHeight) {
        showPopUp();
    }
}

export function showPopUp()
{
    let cookieChoice = getCookie('_tv_cookie_choice');
    let popUpIsShowing = document.getElementById('modal-pop-up-smarter-click');
    if ((cookieChoice !== 'ALL' && cookieChoice !== 'BASIC') || popUpIsShowing) {
        return;
    }

    let cookie = getCookie('_tv_cookie_pop_up_smarter_click');
    if (cookie === '' || !cookie) {
        post(
            '/ajax/pop-ups/smarter-click/render',
            {'pageV2': true},
            function(data) {
                let popUp = data.popUp;
                if (popUp) {
                    let oldModal = getModal('modal-pop-up-smarter-click')
                    if (oldModal) oldModal.hide();
                    openModalFromHtml(popUp);
                    //trackEvent('pop-up', 'exit-page', 'show');
                    trackEvent('Popup', data.popupType, 'show');
                    sendPopupEvent('popup_discount', data.popupType, 'show');
                }
            },
        );
    }
}