import {clickEvent, windowScrollEvent} from "../../webpack/modules/events";
import {isDesktop} from "../../webpack/modules/devices";
import {trackEvent} from "../../webpack/modules/analytics";

clickEvent('#scroll-top-button', false, function() {
    window.scrollTo({top: 0, behavior: 'smooth'});
});

let scrollButton = document.querySelector('#scroll-top-button');
let scrollTimer = null;
var scrollPercentAnalytics = [];
if (scrollButton) {
    windowScrollEvent(function() {
        clearTimeout(scrollTimer);
        scrollTimer = setTimeout(function() {
            if (isDesktop()) {
                if (window.scrollY > window.innerHeight) {
                    scrollButton.classList.remove('d-none');
                } else {
                    scrollButton.classList.add('d-none');
                }
            }
        }, 200);

        var scrollPercent = parseFloat(100 * window.scrollY / (document.body.scrollHeight - window.innerHeight)).toFixed(0);
        if (!scrollPercentAnalytics.includes(scrollPercent)) {
            switch (scrollPercent) {
                case '25':
                    trackEvent('Page', 'Scroll', scrollPercent);
                    scrollPercentAnalytics.push(scrollPercent);
                    break;
                case '50':
                    trackEvent('Page', 'Scroll', scrollPercent);
                    scrollPercentAnalytics.push(scrollPercent);
                    break;
                case '90':
                    trackEvent('Page', 'Scroll', scrollPercent);
                    scrollPercentAnalytics.push(scrollPercent);
                    break;
            }
        }
    });
}