import Splide from "@splidejs/splide";
import {Video} from "@splidejs/splide-extension-video";
import {Grid} from '@splidejs/splide-extension-grid';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
import {fadeIn, fadeOut} from "../element-visibility";
import {document} from "../../../../../../public/vendor/ssr-window";
import {ga4TrackSimpleEvent} from "../analytics";

let imageSlider;
let thumbnailSlider;

export function initProductImageSlider()
{
    let imageCounts = document.querySelectorAll('main.product .image .splide li').length;
    let columns = 2;
    let rows = Math.ceil(imageCounts / columns);
    imageSlider = new Splide('main.product .image .splide', {
        drag: false,
        pagination: false,
        lazyLoad: true,
        arrows: false,
        grid: {
            rows: rows,
            cols: 2,
        },
        breakpoints: {
            992: {
                grid: false,
                drag: true,
                arrows: true,
                pagination: true
            }
        },
    });

    imageSlider.on('mounted move resize',function () {
        let page = imageSlider.index + 1;
        document.querySelector('main.product .image .splide .number-of-page').innerHTML = page;
    });

    imageSlider.on('moved',function () {
        ga4TrackSimpleEvent({
            event: 'product_page',
            method: 'image_scroll '
        });
    });

    imageSlider.mount({Grid});
}

export function initOldProductPageImageSlider()
{
    imageSlider = new Splide('#product-page .image-slider', {
        pagination: false,
        gap: 2,
        heightRatio: 0.95,
        noDrag: '.no-drag',
        breakpoints: {
            767: {
                pagination: true
            }
        },
        video: {
            autoplay: false,
            hideControls: true
        }
    })

    imageSlider.on( 'active', function () {
        let element = document.querySelector('#product-page .image-slider .description');
        if (element && !element.classList.contains('close'))
            document.querySelector('#product-page .image-slider .description .change-description-visibility').click();
    });

    imageSlider.on('video:play', function() {
        if (document.querySelector('.image-slider .labels')) {
            document.querySelector('.image-slider .labels').style.display = 'none';
        }
    });

    imageSlider.on('video:pause', function() {
        if (document.querySelector('.image-slider .labels')) {
            document.querySelector('.image-slider .labels').style.display = 'block';
        }
    });

    imageSlider.on('video:ended', function() {
        if (document.querySelector('.image-slider .labels')) {
            document.querySelector('.image-slider .labels').style.display = 'block';
        }
    });

    thumbnailSlider = new Splide( '#product-page .thumbnail-slider', {
        fixedWidth: 100,
        fixedHeight: 93,
        gap: 10,
        pagination: false,
        arrows: false,
        isNavigation: true
    });

    thumbnailSlider.mount();
    imageSlider.sync(thumbnailSlider);
    imageSlider.mount({ Video });
}

export function addPersonalizedTextSlide(svg, mirrored)
{
    let index = imageSlider.length;
    let slide = `<li class="splide__slide text-center open-images-zoom-popup up-selling-personalized-text-preview" data-thumb="/build/images/web/product/txt.jpg">${svg}</li>`;
    let thumbnailSlide = `<li class="splide__slide text-center up-selling-personalized-text-preview" data-type="jpg">
                                <img class="img-fluid mh-100 mw-100 slide-prod-img" src="/build/images/web/product/txt.jpg">
                          </li>`;
    imageSlider.add(slide);
    thumbnailSlider.add(thumbnailSlide);
    document.querySelector('.image-slider .up-selling-personalized-text-preview svg').classList.add('w-100', 'h-100');
    mirrored = mirrored || 'normal';
    if (mirrored === 'reversed') {
        document.querySelector('.up-selling-personalized-text-preview').classList.add('text-mirrored');
    } else {
        document.querySelector('.up-selling-personalized-text-preview').classList.remove('text-mirrored');
    }
    goToSliderNumber(index);
}

export function removePersonalizedTextSlide()
{
    imageSlider.remove('.up-selling-personalized-text-preview');
    thumbnailSlider.remove('.up-selling-personalized-text-preview');
}

export function updateSliderPreviewImage(productIndex, previewImage)
{
    let imageSlider = document.querySelector(`.image-slider .splide__list .splide__slide[data-type="preview"][data-index="${productIndex}"]`);
    let image = imageSlider.querySelector('img');
    let thumbnailSlider = document.querySelector(`.thumbnail-slider .splide__list .splide__slide[data-type="preview"][data-index="${productIndex}"]`);
    let thumbnail = thumbnailSlider.querySelector('img');
    let number = imageSlider.dataset.number;

    fadeOut(image, 200, function() {
        image.src = previewImage;
        fadeIn(image, 200);
    });
    fadeOut(thumbnail, 200, function() {
        thumbnail.src = previewImage;
        fadeIn(thumbnail, 200);
    });

    goToSliderNumber(number);
}

export function updateSliderPreviewBackground(productIndex, color)
{
    let imageSlider = document.querySelector(`.image-slider .splide__list .splide__slide[data-type="preview"][data-index="${productIndex}"]`);
    let thumbnailSlider = document.querySelector(`.thumbnail-slider .splide__list .splide__slide[data-type="preview"][data-index="${productIndex}"]`);
    let number = imageSlider.dataset.number;

    imageSlider.style.backgroundColor = color;
    thumbnailSlider.style.backgroundColor = color;

    goToSliderNumber(number);
}

function goToSliderNumber(number)
{
    imageSlider.go(parseInt(number));
}

export function initProductListImageSliders()
{
    /* START SINGLE IMAGE A/B TEST */
    let imageTest = document.querySelector('#single-image-test');
    if (!imageTest || imageTest.dataset.value === 'false') {
        let sliders = document.querySelectorAll('#product-list .image-slider');
        sliders.forEach(function(slide) {
            new Splide(
                slide,
                {
                    pagination: false,
                    drag: true,
                    heightRatio : 0.9358,
                }
            ).mount();
        });
    } else {
        let containers = document.querySelectorAll('.splide.image-slider');
        if (containers) {
            containers.forEach((element) => {
                element.classList.add('single-image-test');
            });
            let elements = document.querySelectorAll('.single-image-test .multiple-images');
            if (elements) {
                elements.forEach((element) => {
                    element.remove();
                });
            }
        }
    }
    /* END SINGLE IMAGE A/B TEST */
}