let lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));

if ("IntersectionObserver" in window) {
    let lazyBackgroundObserver = new IntersectionObserver(function(entries) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                entry.target.classList.remove('lazy-background');
                lazyBackgroundObserver.unobserve(entry.target);
            }
        });
    });

    lazyBackgrounds.forEach(function(lazyBackground) {
        lazyBackgroundObserver.observe(lazyBackground);
    });
} else {
    lazyBackgrounds.forEach(function(lazyBackground) {
        lazyBackground.classList.remove('lazy-background');
    });
}