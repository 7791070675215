import {isMobile, isTablet} from "../../webpack/modules/devices";

let whatsappKey = document.querySelector('#whatsapp-key');
let htmlTag = document.getElementsByTagName('html');
let isThankUPage = document.getElementById('thank-u-page');
if (!isThankUPage && whatsappKey && (isMobile() || isTablet())) {
    let key = whatsappKey.value;
    let script = document.createElement('script');
    script.setAttribute('src','https://widget.tochat.be/bundle.js');
    script.dataset.key = key;
    document.head.appendChild(script);
    let helpWidgetType = document.getElementById('help-widget-type');
    if (helpWidgetType) helpWidgetType.dataset.type = 'whatsapp';
}